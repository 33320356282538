<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import CreateWorkgroup from "../components/widgets/workgroup/create.vue";
import AddParticipants from "../components/widgets/workgroup/addParticipants.vue";
import WorkgroupList from "../components/widgets/workgroup/workgroupList.vue";
import deleteWorkgroup from "../components/widgets/workgroup/deleteWorkgroup.vue";
import LeaveWorkgroup from "../components/widgets/workgroup/leaveWorkgroup.vue";
import ModalAddParticipant from "../components/widgets/workgroup/modalAddParticipantV3.vue"
import ModalCreateWorkgroup from "../components/widgets/workgroup/createV3.vue"
import ModalAlertCreateWorkgroup from "../components/widgets/workgroup/modalAlertCreate.vue"

import ModalPackages from "../components/modalPackagesV3.vue"
import ModalCancelPlan from "../components/modalCancelPlan.vue"
import ModalCheckout from "../components/modalCheckoutV3.vue"
import ModalApplePay from "../components/modalApplePay.vue"
import ModalMaxFavWorkgroup from "../components/modalAlertMaxWorkgroup.vue"
import getNameSubDomain from "../utils/getNameSubDomain";

export default {
  data() {
    return {
      notif: true,
      filetype: ".txt",
      footerStyle: {
        position: 'fixed',
        bottom: '20px',
      },
      isEnterprise: true,
      search: '',
      isSecondSidebar: false,
      isSubdomainEnterprise: false,
      activeDropdown: null
    }
  },
  components: {
    CreateWorkgroup,
    AddParticipants,
    WorkgroupList,
    deleteWorkgroup,
    LeaveWorkgroup,
    ModalAddParticipant,
    ModalCreateWorkgroup,
    ModalAlertCreateWorkgroup,
    ModalPackages,
    ModalCancelPlan,
    ModalCheckout,
    ModalApplePay,
    ModalMaxFavWorkgroup
  },
  computed: {
    isSuspend() {
      return this.$store.getters['user/accountSuspend']
    },
    nama() {
          var name = this.$store.getters['user/firstName']
          return this.upperCaseName(name)
    },
    _id() {
      return this.$store.getters['user/id']
    },
    workList() {
          var lists = this.$store.getters['workgroup/workgroups'];
          // return lists.reverse()
          return lists
          // return lists.reverse().filter((item,index)=>index <=5);
    },
    filteredWorkgroup() {
        var itemsSelected = this.$store.getters['workgroup/selected']
        var itemSelected = this.$store.getters['workgroup/item']
        return this.workList.filter((workgroup) => {
            // if((itemsSelected != null && itemsSelected[0].isFolder == true) || (itemSelected != null && itemSelected.isFolder == true)) {
            //     return workgroup.title.toLowerCase().match(this.search.toLowerCase()) && workgroup.type != 'PRIVATE'
            // } else {
            //     return workgroup.title.toLowerCase().match(this.search.toLowerCase())
            // }
            if(workgroup.favorite == true){
              return workgroup.title.toLowerCase().match(this.search.toLowerCase())
            }
        })
    },
    canAddNewWorkgroup(){
        const nameSubdomain = getNameSubDomain();
        if(this.isSuspend == false){
          if(nameSubdomain == process.env.VUE_APP_X_DOMAIN){
            var userPackage = this.$store.getters['user/package'];
            var workgroupsList = this.$store.getters['workgroup/workgroups'];
            var myWorkgroup = workgroupsList.filter((v)=> v.owner_id == this._id && v.enterprise == null)
            return userPackage.workgroup.max_workgroup > myWorkgroup.length
          } else {
            return true
          }
        } else {
          return false
        }
    },
    originalSize(){
      return this.$store.getters['user/size_directory'];
    },
    originalUsed(){
      return this.$store.getters['user/total_used'];
    },
    size(){
      return this.$store.getters['user/total_directory_string']
    },
    used(){
      return this.$store.getters['user/used_directory_string']
    },
    selectedWorkgroup(){
      return this.$store.getters['workgroup/workgroup'];
    },
    state(){
      return this.$store.getters['state/state'];
    },
    listPackages(){
      return this.$store.getters["packages/allPackages"]
    },
    enterpriseList(){
      return this.$store.getters["enterprise/listEnterprise"]
    },
    myEnterprise(){
      return this.$store.getters["enterprise/myEnterprise"]
    },
    isApplePay() {
      return this.$store.getters["user/isApplePay"]
    }
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleOutsideClick);
  },
  mounted: function() {
    this.$store.getters['state/state']

    let links = document.getElementsByClassName("nav-bar-link-ref");
    let matchingMenuItem = null;
    for (let j = 0; j < links.length; j++) {
        if (window.location.pathname === links[j].pathname) {

            matchingMenuItem = links[j];
            break;
        }
    }
    matchingMenuItem.classList.add('active')

    window.addEventListener("click", this.handleOutsideClick)
    this.checkSubdomain()
    if(this.isSubdomainEnterprise == false){
      this.$store.dispatch('enterprise/getMyEnterprise')
      this.$store.dispatch('enterprise/getListEnterprise')
      this.$store.dispatch('packages/getPackages')
    }
    if(this.$router.currentRoute.name == "workgroup"){
      this.$store.dispatch("workgroup/retrieve",this.$router.currentRoute.query.id)
      .then(()=>{
        this.$store.dispatch("workgroup/showDetails");
      });
    }
    this.$store.dispatch("workgroup/workgroupList");
    document.body.setAttribute("data-sidebar", "dark");
    // eslint-disable-next-line no-unused-vars
    // var menuRef = new MetisMenu("#side-menu");

    var activePage = window.location.pathname
    var sideBarMenu = document.querySelector('.personal-side-bar').querySelectorAll('a')
    var sideBarMenuLength = sideBarMenu.length
    for(var i = 0; i < sideBarMenuLength; i++){
        if(sideBarMenu[i].pathname === activePage){
            sideBarMenu[i].classList.add('active')
        }
    }
  },
  methods: {
    deleteWorkgroup(id){
      this.$store.dispatch("workgroup/retrieve",id)
      .then((res)=> {
        this.$root.$emit('bv::show::modal', 'modalDeleteWorkgroup')
      })
    },
    leaveWorkgroup(id){
      this.$store.dispatch("workgroup/retrieve",id)
      .then((res)=> {
        this.$root.$emit('bv::show::modal', 'modalLeaveWorkgroup')
      })
    },
    handleOutsideClick(e){
      if (!e.target.closest(".img-wg-more")) {
        this.activeDropdown = null;
      }
    },
    removeFavorite(id){
      this.$store.dispatch('workgroup/unFavoriteWg',{workgroup_id: id})
      this.activeDropdown = null
    },
    toggleDropdown(itemId) {
      this.activeDropdown = this.activeDropdown === itemId ? null : itemId;

      
      const firstSidebar = document.getElementById('side-bar-menu')
      const btnExpandSidebar = document.getElementById('expand-side-btn')

      const secondSidebar = document.getElementById('side-bar-menu-2')
      const toggleButton = document.getElementById('second-sidebar-toggle')

      const primaryWgList = document.getElementById('primary-wg')
      const enterpriseWgList = document.getElementById('enterprise-wg')

      const imgTogglePrimary = document.getElementById('primary-wg-img-toggle')
      const imgToggleEnterprise = document.getElementById('enterprise-wg-img-toggle')

      document.addEventListener('click', (e) => {
        if (!secondSidebar.contains(e.target) && e.target !== toggleButton) {
          // Using Opacity and Visibility
          if(firstSidebar.style.visibility == 'hidden'){
            secondSidebar.classList.add('active')
            toggleButton.classList.add('active')
            secondSidebar.style.visibility = 'visible'
            secondSidebar.style.opacity = 1
            secondSidebar.style.left = '0px'
            if(primaryWgList){
              // primaryWgList.classList.add('active')
              // enterpriseWgList.classList.add('active')
              // imgTogglePrimary.style.transform = 'rotate(180deg)'
              // imgToggleEnterprise.style.transform = 'rotate(180deg)'
            }
          } else {
            this.isSecondSidebar = false
            secondSidebar.classList.remove('active')
            toggleButton.classList.remove('active')
            secondSidebar.style.visibility = 'hidden'
            secondSidebar.style.opacity = 0
            if(primaryWgList){
              // primaryWgList.classList.add('active')
              // enterpriseWgList.classList.add('active')
              // imgTogglePrimary.style.transform = 'rotate(0deg)'
              // imgToggleEnterprise.style.transform = 'rotate(0deg)'
            }
          }
        }
      })
    },
    checkSubdomain(){
      const nameSubdomain = getNameSubDomain();
      this.isSubdomainEnterprise = !nameSubdomain == process.env.VUE_APP_X_DOMAIN;   
    },
    openSecondSidebar(e){
      this.activeDropdown = null
      const firstSidebar = document.getElementById('side-bar-menu')
      const btnExpandSidebar = document.getElementById('expand-side-btn')

      const secondSidebar = document.getElementById('side-bar-menu-2')
      const toggleButton = document.getElementById('second-sidebar-toggle')

      const primaryWgList = document.getElementById('primary-wg')
      const enterpriseWgList = document.getElementById('enterprise-wg')

      const imgTogglePrimary = document.getElementById('primary-wg-img-toggle')
      const imgToggleEnterprise = document.getElementById('enterprise-wg-img-toggle')

      e.stopPropagation()

      // Using Opacity and Visibility
      if(!secondSidebar.classList.contains('active')){
        this.isSecondSidebar = true
        secondSidebar.classList.add('active')
        toggleButton.classList.add('active')
        secondSidebar.style.visibility = 'visible'
        secondSidebar.style.opacity = 1
        // secondSidebar.style.display = 'block'
        if(primaryWgList){
          primaryWgList.classList.add('active')
          enterpriseWgList.classList.add('active')
          imgTogglePrimary.style.transform = 'rotate(180deg)'
          imgToggleEnterprise.style.transform = 'rotate(180deg)'
        }
      } else {
        this.isSecondSidebar = false
        secondSidebar.classList.remove('active')
        toggleButton.classList.remove('active')
        secondSidebar.style.visibility = 'hidden'
        secondSidebar.style.opacity = 0
        // secondSidebar.style.display = 'none'
        if(primaryWgList){
          primaryWgList.classList.add('active')
          enterpriseWgList.classList.add('active')
          imgTogglePrimary.style.transform = 'rotate(0deg)'
          imgToggleEnterprise.style.transform = 'rotate(0deg)'
        }
      }

      document.addEventListener('click', (e) => {
        if (!secondSidebar.contains(e.target) && e.target !== toggleButton) {
          // Using Opacity and Visibility
          if(firstSidebar.style.visibility == 'hidden'){
            secondSidebar.classList.add('active')
            toggleButton.classList.add('active')
            secondSidebar.style.visibility = 'visible'
            secondSidebar.style.opacity = 1
            // secondSidebar.style.display = 'block'
            secondSidebar.style.left = '0px'
            if(primaryWgList){
              // primaryWgList.classList.add('active')
              // enterpriseWgList.classList.add('active')
              // imgTogglePrimary.style.transform = 'rotate(180deg)'
              // imgToggleEnterprise.style.transform = 'rotate(180deg)'
            }
          } else {
            this.isSecondSidebar = false
            secondSidebar.classList.remove('active')
            toggleButton.classList.remove('active')
            secondSidebar.style.visibility = 'hidden'
            secondSidebar.style.opacity = 0
            // secondSidebar.style.display = 'none'
            if(primaryWgList){
              // primaryWgList.classList.add('active')
              // enterpriseWgList.classList.add('active')
              // imgTogglePrimary.style.transform = 'rotate(0deg)'
              // imgToggleEnterprise.style.transform = 'rotate(0deg)'
            }
          }
        }
      })
    },
    createNewWorkgroup(){
      this.$ga.event(process.env.VUE_APP_GA_CREATE_WORKGROUP, 'Create WG', 'wg-action', 1)
      this.$bvModal.show('modal-create-wg-v3')
    },
    getPackages() {
        if(this.isApplePay == false){
            this.$bvModal.show('modalPackages')
        } else {
            this.$bvModal.show('modal-apple-pay')
        }
    },
    hideDropdown(){
      var dropdownWG = document.querySelector('.side-menu-wg-open')
      dropdownWG.classList.remove('side-menu-wg-open')
    },
    dropdownWG(){
      var sideMenu = document.querySelector(".side-menu-wg")
      sideMenu.classList.toggle('side-menu-wg-open')
    },
    upperCaseName(value) {
      return value.charAt(0).toUpperCase()+value.slice(1)
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    openWorkgroup(item){
      this.$store.dispatch("workgroup/retrieve",item._id)
      .then(()=>{
        if(this.$router.currentRoute.name != "home"){
          this.$router.push({name:"home", query: {wgId: item._id}}).catch(()=>{});
        } else {
          const currentPath = this.$route.path

          this.$router.push({ path: currentPath, query: {wgId: item._id}})
          .catch(err => {
            // Handle the navigation error (redundant navigation)
            if (err.name !== 'NavigationDuplicated') {
              throw err;
            }
            // Optionally, you can perform additional actions if it's a redundant navigation
            // console.log('Redundant navigation prevented.');
          })
        }
        this.$forceUpdate();
        this.$store.dispatch("workgroup/showDetails")
        .then(()=>{
          this.$ga.event('workgroup', 'actionx', 'labelx', 1)
          this.$store.commit(this.state+"/SET_KEYWORD","");
          this.$store.commit(this.state+"/SET_PARENT_ID","");
          this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
          this.$store.commit('storage/SET_ITEM', null)
          this.$store.commit(this.state+"/SET_SHOWBREADCUMB",true)

        })
      });
      // var dropdownWG = document.querySelector('.side-menu-wg-open')
      // dropdownWG.classList.remove('side-menu-wg-open')
    },
    showWorkList(){
      this.$store.dispatch("workgroup/workgroupList").then((items)=>{
          this.$store.commit("workgroup/SET_WORKGROUP",this.selectedWorkgroup == null ? items[0] : this.selectedWorkgroup);
          this.$root.$emit('bv::show::modal', 'workgroupList');
      })
      var dropdownWG = document.querySelector('.side-menu-wg-open')
      dropdownWG.classList.remove('side-menu-wg-open')
    },
    openMyFiles(){
      this.$store.commit("workgroup/SET_WORKGROUP",null);
      this.$store.commit('storage/SET_ITEM', null)
      this.$store.commit("state/SET_STATE","storage");
      this.$store.commit("storage/SET_KEYWORD","");
      this.$store.commit("storage/SET_PARENT_ID","");
      this.$store.commit("storage/DELETE_BREADCUMB",[]);
      this.$store.dispatch('storage/setSidebarDisplay', false)
      this.$store.dispatch("storage/listing")

      // Get the current route's path and query parameters
      // const currentPath = this.$route.path;

      this.$store.dispatch("user/getLimit", null)
      .then(()=>{
        if(this.$route.query){
          const currentQuery = { ...this.$route.query };
    
          // Specify the query parameter you want to remove
          const parameterToRemove = 'wgId';
    
          // Remove the specified query parameter
          delete currentQuery[parameterToRemove];

          this.$router.push({name:"home"}).catch(()=>{})
        }
        if(this.$router.currentRoute.name != "home"){
          this.$router.push({name:"home"}).catch(()=>{});
        }
      });
      this.$store.commit(this.state+"/SET_SHOWBREADCUMB",true)
      var jarak = document.getElementById("jarak");
      jarak.style.display = "none";
      this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
      var header = document.getElementById("page-topbar");
      header.style.right = "0"; 
      // var mainpage = document.getElementById("main-page");
      // mainpage.style.marginRight = "10px";
      if(this.$route.name == 'share' || this.$route.name == 'recent' || this.$route.name == 'trash' || this.$route.name == 'chat' || this.$route.name == 'Setting' || this.$route.name == 'workgroupSetting' || this.$route.name == 'FileShared' || this.$route.name == 'Search') {
        this.$router.push({name:"home"}).catch(()=>{})
      }
      var dropdownWG = document.querySelector('.side-menu-wg-open')
      dropdownWG.classList.remove('side-menu-wg-open')
    },
    openEnterprise(){
      this.$store.commit("workgroup/SET_WORKGROUP",null);
      this.$store.commit('storage/SET_ITEM', null)
      this.$store.commit("state/SET_STATE","enterprise");
      this.$store.commit("storage/SET_KEYWORD","");
      this.$store.commit("storage/SET_PARENT_ID","");
      this.$store.commit("storage/DELETE_BREADCUMB",[]);
      // this.$store.dispatch("storage/listing")
      this.$router.push({name:"EnterpriseAdminOverview"}).catch(()=>{})
      this.$store.dispatch("user/getLimit", null)
      // .then(()=>{
      //   if(this.$router.currentRoute.name != "home"){
      //     this.$router.push({name:"home"}).catch(()=>{});
      //   }
      // });
      // this.$store.commit(this.state+"/SET_SHOWBREADCUMB",true)
      // var jarak = document.getElementById("jarak");
      // jarak.style.display = "none";
      // this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
      // var header = document.getElementById("page-topbar");
      // header.style.right = "0"; 
      // var mainpage = document.getElementById("main-page");
      // mainpage.style.marginRight = "10px";
      // var dropdownWG = document.querySelector('.side-menu-wg-open')
      // dropdownWG.classList.remove('side-menu-wg-open')
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="side-bar-menu">    
    <!-- <div v-if="isSubdomainEnterprise == true">
      SIDEBAR ENTERPRISE
    </div>
    <div v-if="isSubdomainEnterprise == false">
      SIDEBAR DEFAULT
    </div> -->
    <div class="logos-container pt-2 mb-2">
        <img class="logos mb-4" crossorigin="anonymous" :src="this.$logo" alt/>
        <div class="personal-side-bar">
            <ul>
                <!-- <li> -->
                <li @click="openMyFiles()">
                        <span :class="state == 'storage' && isSecondSidebar == false ? 'active' : ''">Personal</span>
                </li>
            </ul>
        </div>
        <div
          id="second-sidebar-toggle"
          @click="openSecondSidebar($event)"
          class="title2 mb-2 cursor-pointer">
            <span>Workgroup</span>
            <img
                src="@/assets/images/icon/chevron-right-angel.svg"
                alt=""
                height="100%"
                data-toggle="tooltip"
                title="Workgroup">
        </div>
        <div class="title mb-2">
            <span>Add workgroup</span>
            <img
                v-if="canAddNewWorkgroup"
                src="@/assets/images/icon/add-wg-plus.svg"
                alt=""
                height="100%"
                data-toggle="tooltip"
                title="Add Workgroup"
                class="cursor-pointer"
                @click="createNewWorkgroup()">
        </div>
        <!-- <div v-if="workList.length > 0" class="side-bar-search-wg mb-2">
            <img src="@/assets/images/icon/search-black.svg" alt="" height="100%">
            <input v-model="search" type="text" placeholder="Search">
        </div> -->
        <div class="devided-line">
            <hr style="margin-top: 0; margin-bottom: 8px; border: 1px solid #E9EAEA;">
        </div>
        <span class="fav-wg-info">Favorite workgroup</span>
    </div>
    <div class="side-bar-container-list-wg mb-2">
        <div v-if="filteredWorkgroup.length <= 0" class="fav-wg-null d-flex flex-column">
          <span>Add a workgroup to favorite</span>
          <span>for easy access.</span>
        </div>
        <ul v-else>
          <li v-for="(work,index) in filteredWorkgroup" :key="index">
            <div
              class="side-bar-link-ref list-wg-user cursor-pointer"
              :class="state == 'workgroup' && selectedWorkgroup._id == work._id ? 'active' : ''">
              <div
                @click="openWorkgroup(work)"
                class="wg-info"
                data-toggle="tooltip"
                :title="`${work.title}. ${work.enterprise != null ? work.enterprise.company_name : 'Primary'}`">
                <span class="title-wg">{{work.title}}</span>
                <span class="sub-title-wg">{{work.enterprise != null ? work.enterprise.company_name : 'Primary'}}</span>
              </div>
              <div class="wg-stars">
                <img v-if="work.favorite != false" src="@/assets/images/icon/yellow-stars.svg" class="img-wg-fav" alt="" height="100%">
                <img @click="toggleDropdown(work._id)" src="@/assets/images/icon/ellipsis-horizontal.svg" class="img-wg-more" alt="" height="100%">
                <div class="dropdown-fav-wg" :class="{ show: activeDropdown === work._id }">
                  <div class="list-item" @click="removeFavorite(work._id)">
                    <img src="@/assets/images/icon/yellow-stars.svg" alt height="100%" />
                    <span class="ml-2 clr-black-262a2c">Unfavorite</span>
                  </div>
                  <div class="list-item" @click="work.owner_id == _id ? deleteWorkgroup(work._id) : leaveWorkgroup(work._id)">
                    <img src="@/assets/images/icon/red-trash.svg" alt height="100%" />
                    <span class="ml-2 clr-red-d42020">{{work.owner_id == _id ? 'Delete Workgroup' : 'Leave Workgroup'}}</span>
                  </div>
                </div>
                <span>Y</span>
              </div>
            </div>
          </li>
            <!-- <li @click="openWorkgroup(work)">
                <div class="side-bar-link-ref list-wg-user" :class="state == 'workgroup' && selectedWorkgroup._id == work._id ? 'active' : ''">
                    <span>{{work.title}}</span>
                </div>
            </li> -->
        </ul>
    </div>

    <!-- Footer -->
    <div :class="myEnterprise != null ? 'side-bar-footer' : 'side-bar-footer-we'">
        <!-- <div v-if="myEnterprise != null" class="devided-line">
            <hr style="margin-top: 0; margin-bottom: 24px; border: 1px solid #E9EAEA;">
        </div> -->
        <div v-if="myEnterprise != null && isSubdomainEnterprise == false" class="my-enterprise">
            <ul>
              <li @click="openEnterprise()"
                :class="state == 'enterprise' && isSecondSidebar == false ? 'active' : ''"
              >
                <div
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Enterprise Admin</span>
                  <img src="@/assets/images/icon/icon-enterprise.svg" alt="" height="100%">
                </div>
              </li>
            </ul>
        </div>
        <div class="d-flex flex-column mt-1 info-sidebar-account">
            <span class="mb-2 info-text-account">{{isSubdomainEnterprise == false ? 'Primary account' : 'Enterprise account'}}</span>
            <b-progress :value="originalUsed" :max="originalSize"></b-progress>
            <span class="mt-2 side-bar-storage-label">{{used}} of {{size}} used.</span>
            <a @click="getPackages()" v-if="listPackages.length != 0 && isSubdomainEnterprise == false" class="side-bar-text-upgrade-storage cursor-pointer mt-3">Upgrade Storage</a>
        </div>
    </div>
    <CreateWorkgroup ref="createWorkgroup"/>
    <AddParticipants ref="addParticipants"/>
    <WorkgroupList ref="workgroupList"/>
    <deleteWorkgroup ref="deleteWorkgroup"/>
    <LeaveWorkgroup/>
    <ModalAddParticipant/>
    <ModalCreateWorkgroup/>
    <ModalAlertCreateWorkgroup/>
    <ModalMaxFavWorkgroup/>
    <ModalPackages v-if="isSubdomainEnterprise == false"/>
    <ModalCancelPlan v-if="isSubdomainEnterprise == false"/>
    <ModalCheckout v-if="isSubdomainEnterprise == false"/>
    <ModalApplePay v-if="isSubdomainEnterprise == false"/>

  </div>
  <!-- Sidebar -->
</template>

<style scoped>
.margin-1rem{
    margin-top: -0.5em;
}
.notifMessage{
  border-radius: 64px;
  background: #00AAFF;
  color: white;
  padding: 2px 10px;
  border: none;
  font-size: 12px;
}
.btn-personal-side-menu{
  width: 12vw;
}
.storage-detail .storage-label{
  color: #919394;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.5px;
}
.sidebar-selected{
  width: 180px;
  background-color: #FFFFFF;
  padding: 8px 10px;
  border-radius: 8px;
  cursor: pointer;
}
.selected-wg{
  transition: 0.3s;
  padding: 0.2em 0.5em;
}
.side-menu-wg{
  position: absolute;
  z-index: 99;
  list-style: none;
  padding: 0.2em 0.5em;
  background-color: #FFFFFF;
  border-radius: 8px;
  opacity: 0;
  display: none;
  transition: 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 66vh;
}
.list-wg-v3{
  max-height: 43vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.side-menu-wg .sidebar-selected:hover{
  background: #f6f6f6;
}
.side-menu-wg-open{
  display: block;
  opacity: 1;
}
.logo-side-nav-v3{
  width: 100% !important;
}
.list-dd-wg-v3{
  width: 160px;
  padding: 6px 0;
}
</style>
