<script>
export default {
    data() {
        return {
        };
    },
    computed: {
        item() {
            return this.$store.getters['share/item']
        },
        owner() {
            return this.$store.getters['user/fullname']
        },
    },
    methods:{
      close(){
        this.$store.commit("share/SET_SHOW_PROPERTIES",false);
        // var header = document.getElementById("page-topbar");
        // header.style.right = "0"; 
        // var mainpage = document.getElementById("main-page");
        // mainpage.style.marginRight = "10px"; 
      },
    }
};
</script>
<template>
    <div>
        <div v-if="item == null" class="empty-item-sideRight mt-2">
            <p>Select a folder or file to see information</p>
        </div>
        <div v-else class="shared-info-sideRight">
            <!-- <pre>{{item}}</pre> -->
            <!-- <div v-if="!item.isShare">
                <img src="@/assets/images/icon/lock-icon.png" alt="" id="img-not-shared-info-sideRight" class="mt-3">
                <div class="text-shared-info-sideRight mt-4 mb-2">This item is not shared</div>
            </div> -->
            <!-- <div v-else> -->
                <div class="d-flex">
                    <div class="mt-3">
                            <b-avatar class="mr-2 mt-2" style="cursor: pointer" variant="primary" size="32px" v-b-tooltip.hover :title="item.users[0].email + ' (Owner)'">{{item.users[0].email.charAt(0).toUpperCase()}}</b-avatar>
                            <b-avatar  v-for="(user,i) in item.users_id" :key="i" class="mr-2 mt-2" style="cursor: pointer" variant="primary" size="32px" v-b-tooltip.hover :title="user.email">{{user.email.charAt(0).toUpperCase()}}</b-avatar>
                    </div>
                </div>
                <div class="mt-4 mb-2">
                    <div class="text-shared-info-sideRight mt-2">This item is shared</div>
                </div>
            <!-- </div> -->
            <!-- <a href="#" class="text-manage-access-sideRight" v-if="!item.isFolder && state != 'workgroup'" @click="shareopen(item)">Manage access</a> -->
        </div>
    </div>
    <!-- <div class="col-3 border-left p-0 properties"  v-if="item != null">
        <div class="d-flex p-4 justify-content-between align-items-center">
            <div   class="d-flex align-items-center">
                <img :src="require(`@/assets/images/icon/${item.file[0].icon}`)" alt />
                <span class="mx-2 wraptext default-text-style">{{item.file[0].file_name}}</span>
            </div>
            <span class="cursor-pointer px-2" @click="close()">
                <img src="@/assets/images/icon/Close.png" alt width="100%" />
            </span>
        </div>
        <b-tabs class="tabs-properties" card>
            <b-tab title="Details" class="px-4">
                <div class="p-2 m-2 d-flex justify-content-center align-items-center">
                    <img :src="require(`@/assets/images/icon/lg/${item.file[0].icon}`)" class="icon-lg" alt />
                </div>
                <div class="mt-4">
                    <p class="default-text-style bold-normal">Has access</p>
                    <div class="d-flex mb-2">
                        <img src="@/assets/images/icon/Icon.png"  v-show="!item.file[0].isShare" class="mx-1" alt />
                        <div class="avatar-xs mx-1" v-show="item.file[0].isShare">
                            <span :id="item.users[0]._id" class="avatar-title rounded-circle">{{item.users[0].firstname.charAt(0).toUpperCase()}}</span>
                            <b-tooltip :target="item.users[0]._id" triggers="hover">
                                {{item.users[0].firstname}} {{item.users[0].lastname}} <b>is owner</b>
                            </b-tooltip>
                        </div>
                        <div class="d-flex">
                            <div class="avatar-xs mx-1" v-for="(user,i) in item.users_id" :key="i">
                                <span :id="user.email" class="avatar-title rounded-circle bg-pink">{{user.email.charAt(0).toUpperCase()}}</span>
                                <b-tooltip :target="user.email" triggers="hover">
                                    {{user.email}} can <b>{{user.access_type}}</b>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                    <p class="default-text-style" v-if="!item.file[0].isShare"><span class="text-muted font-size-12 letThis item is sharedter05">This item is not shared</span></p>
                    <p class="default-text-style" v-else><span class="text-muted font-size-12 letter05">This item is shared</span></p>
                </div>
                <div class="mt-2 pt-4" id="detail">
                    <p class="default-text-style">Details</p>
                    <table class="table default-text-style" :borderless="true" :bordered="false" small width="100%">
                        <tr>
                            <td class="text-muted">Type</td>
                            <td>{{item.file[0].isFolder ? 'Folder' : item.file[0].extension}}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Size</td>
                            <td>{{item.file[0].size}}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Owner</td>
                            <td>{{item.users[0].firstname}} {{item.users[0].lastname}}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Modified</td>
                            <td>{{item.file[0].datemodified}}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Opened</td>
                            <td>{{item.file[0].datemodified}}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Made</td>
                            <td>{{item.file[0].datecreated}}</td>
                        </tr>
                    </table>
                </div>
            </b-tab>
        </b-tabs>
    </div> -->
</template>