<script>
export default {
    computed:{
        item(){
            return this.$store.getters['workgroup/workgroup']
        }
    },
    data() {
        return {
        }
    },
    methods:{
      cancel(){
        this.$ga.event(process.env.VUE_APP_GA_WG_SET_BTN_DEL_WG_CANCEL, 'WG - Del Workgroup Button Cancel', 'wg-settings')
          this.$root.$emit('bv::hide::modal', 'modalDeleteWorkgroup');
      },
      deleteForever(id){
        this.$ga.event(process.env.VUE_APP_GA_WG_SET_BTN_DEL_WG_OK, 'WG - Del Workgroup Button Ok', 'wg-settings', 1)
        this.$store.dispatch("workgroup/deleteForever",id).then(()=>{            
            this.$root.$emit('bv::hide::modal', 'modalDeleteWorkgroup');
            this.$root.$emit('bv::hide::modal', 'workgroupList');
            this.$store.commit("workgroup/SET_WORKGROUP",null);
            this.$store.commit("state/SET_STATE","storage");
            this.$store.commit("storage/SET_KEYWORD","");
            this.$store.commit("storage/SET_PARENT_ID","");
            this.$store.commit("storage/DELETE_BREADCUMB",[]);
            this.$store.dispatch("storage/listing")
            var jarak = document.getElementById("jarak");
            jarak.style.display = "none";
            this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
            // var header = document.getElementById("page-topbar");
            // header.style.right = "0"; 
            // var mainpage = document.getElementById("main-page");
            // mainpage.style.marginRight = "10px";
            this.$router.push({name: 'home'}).catch(()=>{});
        });
      }
    }
};
</script>
<template>
    <b-modal id="modalDeleteWorkgroup" no-fade  content-class="shadow" :hide-header="true"  :hide-footer="true"  centered title="Delete Forever ?">
        <div v-if="item != null">
            <h2 class="title-modal-delete-wg">Delete your workgroup?</h2>
            <ul class="modal-list-delete-wg">
                <li class="modal-delete-alert-wg mb-3">Your files will be gone forever, and you’ll no longer have access to anything that’s been shared with you.</li>
                <li class="modal-delete-alert-wg mb-4">Deleting your account is not reversible. Once deleted, we cannot retrieve it.</li>
            </ul>
            <div class="d-flex justify-content-end align-items-center">
                <button class="mr-3 submit-delete-modal-wg main-color-api" @click="cancel()">Cancel</button>
                <button class="cancel-delete-modal-wg main-btn-negative" @click="deleteForever(item._id)">Yes delete</button>
            </div>
        </div>
    </b-modal>
</template>

<style>
.title-modal-delete-wg{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #262A2C;
    letter-spacing: -1px;
    margin-bottom: 16px;
}
.modal-delete-alert-wg{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #262A2C;
    letter-spacing: -1px;
}
.modal-list-delete-wg{
    padding: 0 0 0 18px;
}
.submit-delete-modal-wg{
    padding: 8px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: none;
    border: none;
    border-radius: 4px;
}
.submit-delete-modal-wg:focus{
    background: none;
}
</style>