<script>
export default {
    data() {
        return {
            invalidTitle: "Please Fill the Field !",
            stateTitle : true,
            formState : [
                {
                    id : "ABOUT",
                    subtitle : "Workgroup are where your team communicate. They’re best when organized around a topic.",
                    active : true
                },
                {
                    id : "PARTICIPANTS",
                    subtitle : "e.g. example@jaybod.com",
                    active : false
                }
            ],
            emailuser : "",
            accessTypes : [
                {
                    id : "View",
                    label : "Viewer",
                    selected : true
                },
                {
                    id : "Write",
                    label : "Editor",
                    selected : false
                }
            ],
            validEmail : true,
            participants : [],
            keyword : "",
            keywordParticipants : "", 
            addParticipantState : false
        }
    },
    computed: {
        filterParticipants(){
            if(this.item.participant.email === ""){
                return this.item.participant.email
            }
            return this.item.participant.filter((user) => {
                return user.email.match(this.keywordParticipants)
            })
        },
        getFormState(){
            return this.formState.filter((v)=>v.active)[0];
        },
        selectedAccess(){
            return this.accessTypes.filter(v=>v.selected)[0]
        },
        loading(){
            return this.$store.getters["workgroup/loading"];
        },
        title: {
        // getter
            get: function () {
                return this.$store.getters['workgroup/title'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_TITLE",newValue);
            }
        },
        description: {
        // getter
            get: function () {
                return this.$store.getters['workgroup/description'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_DESCRIPTION",newValue);
            }
        },
        fullname() {
            return this.$store.getters['user/fullname']
        },
        item() {
            return this.$store.getters['workgroup/workgroup']
        },
        canAddNewMember(){
            var userPackage = this.$store.getters['user/package'];
            var participants = this.item.participant;
            return userPackage.workgroup.max_participants > participants.length
        },
        isOwner(){
            var id = this.$store.getters['user/id'];
            var group = this.$store.getters['workgroup/workgroup'];
            return id == group.owner_id;
        }
    },
    mounted(){
        this.title = this.item.title
    },
    methods:{
        selectAccestype(index){
            this.accessTypes = this.accessTypes.map((v,i)=>{
                return {label:v.label,selected:i==index,id:v.id}
            })
        },
        searchParticipants(){
            
        },
        onEnter(){
            if(this.validateEmail(this.emailuser)){
                var exists = this.participants.filter((v)=>v.email == this.emailuser);
                if(exists.length == 0){
                    this.participants.push(
                        {
                            email : this.emailuser,
                            access_type : this.selectedAccess
                        }
                    )
                }
            }
        },
        changeAccess(access,index){
            this.participants[index].access_type = access;
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        close(){
            this.participants = []
            this.title = "";
            this.description = "";
            this.addParticipantState = false;
            this.formState = [
                {
                    id : "ABOUT",
                    subtitle : "Workgroup are where your team communicate. They’re best when organized around a topic.",
                    active : true
                },
                {
                    id : "PARTICIPANTS",
                    subtitle : "e.g. example@jaybod.com",
                    active : false
                }
            ];
            this.$root.$emit('bv::hide::modal', 'detailWorkgroup');
        },
        update(){
            var data = {
                id : this.item._id,
                title : this.title,
                description : this.description
            }
            this.$store.dispatch("workgroup/updateWorkgroup",data)
        },
        updateAccess(participant){
            var data = {
                id : this.item._id,
                email : participant.email,
                access_type : participant.access_type == "View" ? "Write" : "View"
            }
            this.$store.dispatch("workgroup/changeAccess",data)
        },
        remove(participant){
            this.$store.commit("workgroup/SET_SELECTED_PARTICIPANT",participant)
            this.$root.$emit('bv::show::modal', 'deleteParticipants');
        },
        showFormParticipant(){
            this.addParticipantState = !this.addParticipantState
        },
        hapus(email){
            this.participants = this.participants.filter((v)=>v.email != email);
        },
        create(){
            this.$ga.event('add_participants_wg', 'actionx', 'labelx', 1)

            var param = {
                title : this.item.title,
                description : this.item.description,
                participant : this.participants.map((v)=>{
                    return {
                        email:v.email,
                        access_type : v.access_type.id
                    }
                })
            }
            this.$store.dispatch("workgroup/create",param).then(()=>{
                this.participants = []
                this.title = "";
                this.description = "";
                this.addParticipantState = false;
                this.formState = [
                    {
                        id : "ABOUT",
                        subtitle : "Workgroup are where your team communicate. They’re best when organized around a topic.",
                        active : true
                    },
                    {
                        id : "PARTICIPANTS",
                        subtitle : "e.g. example@jaybod.com",
                        active : false
                    }
                ];
            });
            this.$root.$emit('bv::hide::modal', 'createWorkgroup');
            this.$root.$emit('bv::hide::modal', 'detailWorkgroup');
        },
    }
};
</script>
<template>
    <b-modal id="detailWorkgroup"  no-fade modal-sm no-stacking  content-class="shadow" :hide-header="true" :hide-footer="true"  centered >
        <div class="header p-2">
           <div class="d-flex pb-2 px-4 justify-content-between align-items-center mt-4">
                <div   class="d-flex align-items-center">
                    <img src="@/assets/images/icon/workgroup.png" alt  />
                    <span class="mx-2 wraptext default-text-style bold-normal">{{item.title}}</span>
                </div>
                <span class="cursor-pointer px-2" @click="close()">
                    <img src="@/assets/images/icon/Close.png" alt width="100%" />
                </span>
            </div>
        </div>
        <b-tabs  class="tabs-properties" card >
            <b-tab title="About" class="px-1">
                <div class="mx-3 mb-4">
                    <b-form-group id="title" :invalid-feedback="invalidTitle" :state="stateTitle" class="mt-4">
                        <b-form-input id="firstname"  class="login-input-style" autocomplete="false" :readonly="!isOwner" placeholder="e.g. Plan Budget" v-model="title"  trim></b-form-input>
                    </b-form-group>
                    <b-form-group id="description" class="mt-4">
                        <b-form-textarea id="textarea-rows"  class="login-input-style" :readonly="!isOwner" placeholder="Description (Optional) " v-model="description" rows="5"></b-form-textarea>
                    </b-form-group>
                    <div class="d-flex justify-content-between align-item-center">
                        <a href="javascript:void(0);" @click="close()" class="default-text-style"><span class="text-blue">Cancel</span></a>
                        <button  class="btn base-btn bg-blue btn-storage"  :disabled="loading" @click="update()" v-if="isOwner">      
                            <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>              
                            <span class="default-text-style">Save Changes</span>
                        </button>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="`Participants (${item.participant.length})`" class="px-1">
                <div class="mx-4 mb-4">
                    <div v-if="canAddNewMember">
                        <button  class="btn base-btn bg-blue btn-storage"  @click="showFormParticipant()" v-if="!addParticipantState && isOwner">                
                            <span class="default-text-style"> <i class="bx bx-user-plus"></i> Add New Workgroup Member</span>
                        </button>
                    </div>
                    <div v-else>
                        <router-link tag="a" to="/packages" class="btn base-btn bg-blue btn-storage">             
                            <span class="default-text-style"> <i class="bx bxs-cart"></i> Upgrade for add new member</span>
                        </router-link>
                    </div>
                    <!-- <img src="@/assets/images/btn_add_prt.png" @click="showFormParticipant()" alt class="cursor-pointer " v-if="!addParticipantState && isOwner" /> -->
                    <div class="my-1" v-if="addParticipantState && isOwner">
                        <b-form-group id="input-group-1" class="pt-4">
                            <b-input-group>
                                <b-form-input id="input-1" v-on:keyup.enter="onEnter" v-model="emailuser" class="login-input-style border-right-none"  type="email" placeholder="Email"></b-form-input>
                                <template #append>
                                    <b-dropdown class="dropdown-input" right>
                                        <template #button-content>
                                            <span class="text-blue default-text-style"><span class="text-blue">{{selectedAccess.label}}</span></span>
                                            <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                                        </template>
                                        <b-dropdown-item v-for="(access,index) in accessTypes" :key="index" @click="selectAccestype(index)">
                                            <i v-if="access.selected" class="dripicons-checkmark px-2  text-blue"></i>
                                            <span v-else class="px-3"></span>
                                            <span class="text-dark default-text-style">{{access.label}}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>                
                            </b-input-group>
                            <b-form-invalid-feedback :state="validEmail">
                                Your Email Invalid Format
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <form class="app-search pb-0 pt-4 d-none d-lg-block" @submit.prevent="searchParticipants">
                        <div class="position-relative">
                            <input type="search" v-model="keywordParticipants" class="form-control  login-input-style"  placeholder="Find Participants..." />
                            <span class="bx bx-search-alt"></span>
                        </div>
                    </form>
                    <div class="py-4">
                        <div class="d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="avatar-xs">
                                    <span class="avatar-title rounded-circle">{{item.name.charAt(0).toUpperCase()}}</span>
                                </div>
                                <span class=" default-text-style text-dark px-2">{{item.name}}</span>
                            </div>
                            <span class=" default-text-style text-dark">Owner</span>
                        </div>
                        <div class="d-flex justify-content-between align-items-center py-2" v-for="(participant) in participants" :key="participant._id">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="avatar-xs">
                                    <span class="avatar-title rounded-circle bg-red">{{participant.email.charAt(0).toUpperCase()}}</span>
                                </div>
                                <span class=" default-text-style text-dark px-2">{{participant.email}}</span>
                            </div>
                            <b-dropdown class="dropdown-input border-none" left>
                                <template #button-content>
                                    <span class="text-dark default-text-style ">{{participant.access_type.label}}</span>
                                    <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                                </template>
                                <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="changeAccess(access,index)">
                                    <i v-if="access.label == participant.access_type.label" class="dripicons-checkmark px-2  text-blue"></i>
                                    <span v-else class="px-3"></span>
                                    <span class="text-dark">{{access.label}}</span>
                                </b-dropdown-item>

                                <hr class="mx-2 mt-1 mb-1">
                                
                                <b-dropdown-item button @click="hapus(participant.email)">
                                    <!-- <i class="dripicons-checkmark px-2  text-blue"></i> -->
                                    <span class="text-danger">remove</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="d-flex justify-content-between align-items-center py-2" v-for="(participant,index) in filterParticipants" :key="index">
                            <div v-if="item.owner_id != participant._id" class="d-flex justify-content-start align-items-center">
                                <div class="avatar-xs">
                                    <span class="avatar-title rounded-circle">{{participant.firts_name.charAt(0).toUpperCase()}}</span>
                                </div>
                                <span class=" default-text-style text-dark px-2">{{participant.firts_name}} {{ participant.lastname }}</span>
                            </div>
                            <b-dropdown class="dropdown-input border-none" left v-if="isOwner">
                                <template v-if="item.owner_id != participant._id" #button-content>
                                    <span class="text-dark default-text-style ">{{participant.access_type}}</span>
                                    <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                                </template>
                                
                                <b-dropdown-item button @click="updateAccess(participant)">
                                    <!-- <i class="dripicons-checkmark px-2  text-blue"></i> -->
                                    <span class="text-dark">{{participant.access_type == "View" ? "Write" : "View" }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="remove(participant)">
                                    <!-- <i class="dripicons-checkmark px-2  text-blue"></i> -->
                                    <span class="text-danger">remove</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
                <div class="mt-2 mx-3 px-3 pt-4 d-flex justify-content-between align-items-center" v-if="participants.length > 0">
                    <a href="javascript:void(0);" @click="close()" class="default-text-style"><span class="text-blue">Cancel</span></a>
                    <button  class="btn base-btn bg-blue btn-storage"  :disabled="loading" @click="create()">      
                        <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>              
                        <span class="default-text-style">Send Invitations</span>
                    </button>
                </div>
            </b-tab>
            <!-- <b-tab title="Activity">Activity</b-tab> -->
        </b-tabs>
    </b-modal>
</template>