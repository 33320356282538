<template>
  <b-modal ref="modal-cancel-plan-v3" id="modal-cancel-plan" centered no-close-on-backdrop hide-header hide-footer>
    <span class="title-modal-change-password">Are you sure you want to cancel your plan?</span>
    <div class="d-flex justify-content-end mt-3">
      <button @click="close()" class="btn-keep-plan-v3 main-color-api">Keep plan</button>
      <button @click="submitCancel()" class="ml-3 btn-submit-cancel-plan main-btn-api">Cancel plan</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalCancelPlan',
  methods: {
    close() {
      this.$bvModal.hide('modal-cancel-plan')
    },
    submitCancel() {
      this.$store.dispatch('user/cancelPlan')
      .then((res) => {
        this.$bvModal.hide('modal-cancel-plan')
      })
    }
  }
}
</script>

<style>
.btn-submit-cancel-plan{
    height: 40px;
    width: 120px;
    color: #FFFFFF;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    border: none;
}
.btn-keep-plan-v3{
    height: 40px;
    width: 90px;
    background: #FFFFFF;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    border: none;
}
.btn-keep-plan-v3:focus{
    background: none;
}
</style>