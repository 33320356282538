
<script>
export default {
    computed:{
        item(){
            return this.$store.getters["workgroup/workgroup"];
        },
        selectedParticipant(){
            return this.$store.getters["workgroup/selectedParticipant"];
        }
    },
    data() {
        return {
            folderName: "Folder New",
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            counter : 0
        };
    },
    methods:{
      cancel(){
          this.$root.$emit('bv::hide::modal', 'deleteParticipants');
      },
      deleteForever(){
        var param = {
            workgroup_id : this.item._id,
            email : this.selectedParticipant.email
        }
        this.$store.dispatch("workgroup/removeParticipant",param).then(()=>{
            this.$root.$emit('bv::hide::modal', 'deleteParticipants');
            this.$store.dispatch("workgroup/retrieve",this.item._id);
        });
      }
    }
};
</script>
<template>
    <b-modal id="deleteParticipants" no-fade  content-class="shadow" :hide-header="true"  :hide-footer="true"  centered title="Delete Forever ?">
        <div>
            <h2 class="modal-title text-dark default-text-style">Remove Participant 🚫</h2>
            <p class="mt-3 default-text-style">Removing "{{selectedParticipant.email}}" from this group.</p>
            <div class="d-flex justify-content-end align-items-center">
                <a href="javascript:void();" @click="cancel()" class="default-text-style"><span class="text-blue">Cancel</span></a>
                <button class="btn base-btn btn-storage mx-4 " @click="deleteForever()">
                    <span class="default-text-style">Yes Remove</span>
                </button>
            </div>
        </div>
    </b-modal>
</template>