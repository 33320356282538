<template>
  <b-modal id="delete-account"  no-fade modal-sm  content-class="shadow" :hide-header="true" :hide-footer="true" centered >
    <p><span class="default-text-style"><strong>Delete account permanently?</strong></span></p>
    <p class="default-text-style">You will lose access to your account and all the files will be permanently deleted.</p>
    <b-form class="p-2" @submit.prevent="submit()">
      <b-form-group id="password-del" :invalid-feedback="invalidPassword" :state="statePassword" class="py-2 mt-2" label-class="label-input float-left">
          <b-input-group>
              <b-form-input
                  id="password-del"
                  v-model="password"
                  type="password"
                  class="login-input-style form-control"
                  placeholder="Password"
                  >
              </b-form-input>
              <!-- <b-input-group-append>
                  <b-button   @click="()=>{passViewState = (passViewState == 'password') ?  'text' : 'password' }"  class="btn-on-input">
                    <i :class="(passViewState != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i>
                  </b-button>
              </b-input-group-append> -->
          </b-input-group>
      </b-form-group>
      <div class="mt-4 pt-4 d-flex justify-content-end align-items-center">
          <a href="javascript:void(0);" @click="$bvModal.hide('delete-account')" class="default-text-style bold-normal mx-4"><span class="text-blue">Cancel</span></a>
          <!-- <button type="submit" class="btn base-btn btn-storage ml-4" :disabled="!password.length" @click="submit()"> -->
          <button type="submit" :class="password.length >= 1 ? 'active btn base-btn' : 'btn base-btn'" :disabled="!password.length" @click="submit()">
            <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
            <span class="default-text-style"><span :class="password.length >= 1 ? 'text-white' : ''">Delete</span></span>
          </button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>

export default {
  name: 'ModalDeleteAccount',
  data() {
    return {
      password: '',
      invalidPassword: '',
      statePassword : true,
      loading : false,
      authError: null,
      isAuthError: false,
      isActive: true
    }
  },
  methods: {
    submit() {
      if(this.password == ""){
          this.statePassword = false;
          return false
      }
      const pw = {password: this.password}
      this.$store.dispatch('user/deleteAccount', pw)
      .then(()=>{
          this.$root.$emit('bv::hide::modal', 'delete-account');
      })
      .catch(error => {
        this.invalidPassword = error ? 'Incorrect password. Please check it again or try another password.' : "";
        this.statePassword = false;
        this.loading = false;
      })
    }
  }
}
</script>

<style>

</style>