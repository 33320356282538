<script>
export default {
    data() {
        return {
            invalidTitle: "Please Fill the Field !",
            stateTitle : true,
            emailuser : "",
            accessTypes : [
                {
                    id : "View",
                    label : "can view",
                    selected : true
                },
                {
                    id : "Edit",
                    label : "can edit",
                    selected : false
                }
            ],
            validEmail : true
        }
    },
    computed: {
        title: {
        // getter
            get: function () {
                return this.$store.getters['workgroup/title'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_TITLE",newValue);
            }
        },
        description: {
        // getter
            get: function () {
                return this.$store.getters['workgroup/description'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_DESCRIPTION",newValue);
            }
        },
        selectedAccess(){
            return this.accessTypes.filter(v=>v.selected)[0]
        },
    },
    methods:{
        close(){
            this.$root.$emit('bv::hide::modal', 'addParticipants');
        },
        selectAccestype(index){
            this.accessTypes = this.accessTypes.map((v,i)=>{
                return {label:v.label,selected:i==index,id:v.id}
            })
        },
    }
};
</script>
<template>
    <b-modal id="addParticipants"  no-fade modal-sm  content-class="shadow" :hide-header="true" :hide-footer="true"  centered >
        <div class="d-flex justify-content-between align-items-center m-3">
            <div class="d-flex align-item-center justify-content-start">
                <img src="@/assets/images/icon/workgroup.png" alt  />
                <span class="default-text-style bold-normal text-dark">{{title}}</span>
            </div>
            <span class="cursor-pointer px-2" @click="close()">
                <img src="@/assets/images/icon/Close.png" alt width="100%" />
            </span>
        </div>
        <div class="mx-3 mb-4">
            <span class="default-text-style bold-normal text-dark">Add participant 🤚</span>
            <p class="default-text-style my-2"><span class="text-muted">e.g. example@jaybod.com</span></p>
            <b-form-group id="input-group-1" class="py-4">
                <b-input-group>
                    <b-form-input id="input-1" v-model="emailuser" class="login-input-style border-right-none"  type="email" placeholder="Email"></b-form-input>
                    <template #append>
                        <b-dropdown class="dropdown-input" right>
                            <template #button-content>
                                <span class="text-blue default-text-style"><span class="text-blue">{{selectedAccess.label}}</span></span>
                                <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                            </template>
                            <b-dropdown-item v-for="(access,index) in accessTypes" :key="index" @click="selectAccestype(index)">
                                <i v-if="access.selected" class="dripicons-checkmark px-2  text-blue"></i>
                                <span v-else class="px-3"></span>
                                <span class="text-dark default-text-style">{{access.label}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>                
                </b-input-group>
                <b-form-invalid-feedback :state="validEmail">
                    Your Email Invalid Format
                </b-form-invalid-feedback>
            </b-form-group>
        </div>
        <div class="my-2 mx-3 py-2 d-flex justify-content-end align-items-center">
            <button class="btn base-btn btn-storage">
                <span class="default-text-style">Next</span>
            </button>
        </div>
    </b-modal>
</template>