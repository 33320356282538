<template>
    <div>
        <b-tabs class="tabs-files-in-chat" active-nav-item-class="active-tabs">
            <b-tab title="Media">
                <div v-if="workgroup.type == 'PRIVATE'" class="items-display">
                    <div v-if="filterItems.media.length <= 0" class="empty-item-sideRight mt-2">
                        <p>No media shared in this chat</p>
                    </div>
                    <div v-if="filterItems.media.length > 0">
                        <b-list-group v-for="(item, idx) in filterItems.media" :key="idx">
                            <b-list-group-item class="list-group-files-in-chat">
                                <div class="d-flex">
                                    <div class="file-name-in-chat" data-toggle="tooltip" :title="item.file.file_name">
                                        <img v-if="item.file.encrypt == null" :src="require(`@/assets/images/icon/${getImage(item.file.extension)}`)" alt height="24px" class="mb-1"/>
                                        <img v-else-if="item.file.encrypt != null && (item.file.encrypt.code != 2 && item.file.encrypt.code != 4 && item.file.encrypt.code != 7)" :src="require(`@/assets/images/icon/${getImage(item.file.extension)}`)" alt height="24px" class="mb-1"/>
                                        <img v-else src="@/assets/images/icon/lock-encrypt.svg" alt="" height="24px" class="mb-1">
                                        <span class="ml-2 name-file-in-chat">{{item.file.file_name}}</span>
                                    </div>
                                    <b-dropdown class="cursor-pointer files-in-chat-dropdown" id="files-media-dropdown">
                                        <template #button-content>
                                            <img class="ml-3" src="@/assets/images/icon/More.png" alt="" height="24px">
                                        </template>
                                        <b-dropdown id="sub-files-media-dropdown">
                                            <template #button-content>
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open</span>
                                                <img src="@/assets/images/icon/chevLeft.svg" alt="" class="ml-5">
                                            </template>
                                            <b-dropdown-item-button @click="open(item)">
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open file</span>
                                            </b-dropdown-item-button>
                                            <b-dropdown-item-button @click="openLocation(item)">
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open location</span>
                                            </b-dropdown-item-button>
                                        </b-dropdown>
                                        <b-dropdown-item-button v-if="id == item.user" @click="deleteChat(item)">
                                            <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                            <span class="ml-3 default-text-style">Delete</span>
                                        </b-dropdown-item-button>
                                        <b-dropdown-item-button @click="downloadFile(item)">
                                            <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                            <span class="ml-3 default-text-style">Download</span>
                                        </b-dropdown-item-button>
                                    </b-dropdown>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                        <!-- <pre>{{items.media}}</pre> -->
                    </div>
                </div>
                <div v-if="workgroup.type != 'PRIVATE'" class="items-display">
                    <div v-if="items.media.length <= 0" class="empty-item-sideRight mt-2">
                        <p>No media shared in this chat</p>
                    </div>
                    <div v-if="items.media.length > 0">
                        <b-list-group v-for="(item, idx) in items.media" :key="idx">
                            <b-list-group-item class="list-group-files-in-chat">
                                <div class="d-flex">
                                    <div class="file-name-in-chat" data-toggle="tooltip" :title="item.file.file_name">
                                        <img v-if="item.file.encrypt == null" :src="require(`@/assets/images/icon/${getImage(item.file.extension)}`)" alt height="24px" class="mb-1"/>
                                        <img v-else-if="item.file.encrypt != null && (item.file.encrypt.code != 2 && item.file.encrypt.code != 4 && item.file.encrypt.code != 7)" :src="require(`@/assets/images/icon/${getImage(item.file.extension)}`)" alt height="24px" class="mb-1"/>
                                        <img v-else src="@/assets/images/icon/lock-encrypt.svg" alt="" height="24px" class="mb-1">
                                        <span class="ml-2 name-file-in-chat">{{item.file.file_name}}</span>
                                    </div>
                                    <b-dropdown class="cursor-pointer files-in-chat-dropdown" id="files-media-dropdown">
                                        <template #button-content>
                                            <img class="ml-3" src="@/assets/images/icon/More.png" alt="" height="24px">
                                        </template>
                                        <b-dropdown id="sub-files-media-dropdown">
                                            <template #button-content>
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open</span>
                                                <img src="@/assets/images/icon/chevLeft.svg" alt="" class="ml-5">
                                            </template>
                                            <b-dropdown-item-button @click="open(item)">
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open file</span>
                                            </b-dropdown-item-button>
                                            <b-dropdown-item-button @click="openLocation(item)">
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open location</span>
                                            </b-dropdown-item-button>
                                        </b-dropdown>
                                        <b-dropdown-item-button v-if="id == item.user" @click="deleteChat(item)">
                                            <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                            <span class="ml-3 default-text-style">Delete</span>
                                        </b-dropdown-item-button>
                                        <b-dropdown-item-button @click="downloadFile(item)">
                                            <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                            <span class="ml-3 default-text-style">Download</span>
                                        </b-dropdown-item-button>
                                    </b-dropdown>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                        <!-- <pre>{{items.media}}</pre> -->
                    </div>
                </div>
            </b-tab>
            <b-tab title="Docs">
                <div v-if="workgroup.type == 'PRIVATE'" class="items-display">
                    <div v-if="filterItems.files.length <= 0" class="empty-item-sideRight mt-2">
                        <p>No docs shared in this chat</p>
                    </div>
                    <div v-if="filterItems.files.length > 0">
                        <b-list-group v-for="(item, idx) in filterItems.files" :key="idx">
                            <b-list-group-item class="list-group-files-in-chat">
                                <div class="d-flex">
                                    <div class="file-name-in-chat" data-toggle="tooltip" :title="item.file.file_name">
                                        <img v-if="item.file.encrypt == null" :src="require(`@/assets/images/icon/${getImage(item.file.extension)}`)" alt height="24px" class="mb-1"/>
                                        <img v-else-if="item.file.encrypt != null && (item.file.encrypt.code != 2 && item.file.encrypt.code != 4 && item.file.encrypt.code != 7)" :src="require(`@/assets/images/icon/${getImage(item.file.extension)}`)" alt height="24px" class="mb-1"/>
                                        <img v-else src="@/assets/images/icon/lock-encrypt.svg" alt="" height="24px" class="mb-1">
                                        <span class="ml-2 name-file-in-chat">{{item.file.file_name}}</span>
                                    </div>
                                    <b-dropdown class="cursor-pointer files-in-chat-dropdown" id="files-docs-dropdown">
                                        <template #button-content>
                                            <img class="ml-3" src="@/assets/images/icon/More.png" alt="" height="24px">
                                        </template>
                                        <b-dropdown id="sub-files-docs-dropdown">
                                            <template #button-content>
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open</span>
                                                <img src="@/assets/images/icon/chevLeft.svg" alt="" class="ml-5">
                                            </template>
                                            <b-dropdown-item-button @click="open(item)">
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open file</span>
                                            </b-dropdown-item-button>
                                            <b-dropdown-item-button @click="openLocation(item)">
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open location</span>
                                            </b-dropdown-item-button>
                                        </b-dropdown>
                                        <b-dropdown-item-button v-if="id == item.user" @click="deleteChat(item)">
                                            <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                            <span class="ml-3 default-text-style">Delete</span>
                                        </b-dropdown-item-button>
                                        <b-dropdown-item-button @click="downloadFile(item)">
                                            <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                            <span class="ml-3 default-text-style">Download</span>
                                        </b-dropdown-item-button>
                                    </b-dropdown>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                        <!-- <pre>{{items.files}}</pre> -->
                    </div>
                </div>
                <div v-if="workgroup.type != 'PRIVATE'" class="items-display">
                    <div v-if="items.files.length <= 0" class="empty-item-sideRight mt-2">
                        <p>No docs shared in this chat</p>
                    </div>
                    <div v-if="items.files.length > 0">
                        <b-list-group v-for="(item, idx) in items.files" :key="idx">
                            <b-list-group-item class="list-group-files-in-chat">
                                <div class="d-flex">
                                    <div class="file-name-in-chat" data-toggle="tooltip" :title="item.file.file_name">
                                        <img v-if="item.file.encrypt == null" :src="require(`@/assets/images/icon/${getImage(item.file.extension)}`)" alt height="24px" class="mb-1"/>
                                        <img v-else-if="item.file.encrypt != null && (item.file.encrypt.code != 2 && item.file.encrypt.code != 4 && item.file.encrypt.code != 7)" :src="require(`@/assets/images/icon/${getImage(item.file.extension)}`)" alt height="24px" class="mb-1"/>
                                        <img v-else src="@/assets/images/icon/lock-encrypt.svg" alt="" height="24px" class="mb-1">
                                        <span class="ml-2 name-file-in-chat">{{item.file.file_name}}</span>
                                    </div>
                                    <b-dropdown class="cursor-pointer files-in-chat-dropdown" id="files-docs-dropdown">
                                        <template #button-content>
                                            <img class="ml-3" src="@/assets/images/icon/More.png" alt="" height="24px">
                                        </template>
                                        <b-dropdown id="sub-files-docs-dropdown">
                                            <template #button-content>
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open</span>
                                                <img src="@/assets/images/icon/chevLeft.svg" alt="" class="ml-5">
                                            </template>
                                            <b-dropdown-item-button @click="open(item)">
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open file</span>
                                            </b-dropdown-item-button>
                                            <b-dropdown-item-button @click="openLocation(item)">
                                                <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                <span class="ml-3 default-text-style">Open location</span>
                                            </b-dropdown-item-button>
                                        </b-dropdown>
                                        <b-dropdown-item-button v-if="id == item.user" @click="deleteChat(item)">
                                            <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                            <span class="ml-3 default-text-style">Delete</span>
                                        </b-dropdown-item-button>
                                        <b-dropdown-item-button @click="downloadFile(item)">
                                            <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                            <span class="ml-3 default-text-style">Download</span>
                                        </b-dropdown-item-button>
                                    </b-dropdown>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                        <!-- <pre>{{items.files}}</pre> -->
                    </div>
                </div>
            </b-tab>
            <b-tab title="Link">
                <div class="items-display">
                    <div v-if="items.links.length <= 0" class="empty-item-sideRight mt-2">
                        <p>No link shared in this chat</p>
                    </div>
                    <div v-if="items.links.length > 0">
                        <b-list-group v-for="(item, idx) in items.links" :key="idx">
                            <b-list-group-item class="list-group-files-in-chat">
                                <div class="d-flex">
                                    <div class="align-items-center  text-link-overflow">
                                        <img class="mr-1 mb-1" src="@/assets/images/icon/fi-rr-world.svg" alt="" height="24px">
                                        <a :href="item.link" target="_blank" data-toggle="tooltip" :title="item.link" class="font-link-text">{{item.link}}</a>
                                    </div>
                                    <b-dropdown v-if="id == item.user" class="cursor-pointer files-in-chat-dropdown" id="files-links-dropdown">
                                        <template #button-content>
                                            <img class="ml-3" src="@/assets/images/icon/More.png" alt="" height="24px">
                                        </template>
                                        <b-dropdown-item-button @click="deleteChat(item)">
                                            <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                            <span class="ml-3 default-text-style">Delete</span>
                                        </b-dropdown-item-button>
                                    </b-dropdown>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                        <!-- <pre>{{items.links}}</pre> -->
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import Storage from '../../../api/storage'
import Helper from "@/api/helper"

export default {
    name: 'FilesInChat',
    data() {
        return {
            isSubMediaVisible: false,
            isSubDocsVisible: false,
            isSubLinksVisible: false
        }
    },
    computed: {
        items() {
            return this.$store.getters["chat/filesChat"]
        },
        filterItems() {
            var items = this.items
            if(this.id == this.workgroup.owner_id){
                return items
            } else {
                var media = items.media.filter(item => item.file.added_by == this.id)
                var files = items.files.filter(item => item.file.added_by == this.id)
                var newItems = {}
                newItems.media = media
                newItems.files = files
                newItems.links = items.links

                return newItems
            }
        },
        state(){
            return this.$store.getters["state/state"]
        },
        canAccess(){
            var id = this.$store.getters['user/id'];
            var group = this.$store.getters['workgroup/workgroup'];
            var st = this.$store.getters['state/state'];
            if(st == "storage"){
              return true
            }else{
              if(id == group.owner_id){
                return true;
              }else{
                return group.access_type != "View";
              }
            }
        },
        id(){
            return this.$store.getters["user/id"]
        },
        workgroup() {
            return this.$store.getters['workgroup/workgroup']
        }
    },
    methods: {
        sendInfoDecrypt(item){
            var payload = {
                _id: item.file.id,
                directory: item.file.directory,
                encrypt: item.file.encrypt,
                extension: item.file.extension,
                file_name: item.file.file_name,
                hash_name: item.file.hash_name,
                parent_id: item.file.parent_id,
                size: item.file.size,
                type: item.file.type
            }
            this.item = payload
            this.$root.$emit('bv::show::modal', 'modal-decrypt')
        },
        downloadFile(list){
            this.$store.dispatch("storage/fileretrieve",{_id : list.file.id,directory:list.file.directory})
            .then(()=>{
                this.$root.$emit('bv::show::modal', 'modalDownload');
            })
        },
        openLocation(list){
            if(list.file.parent_id == ''){
                this.$store.commit(this.state+"/SET_PARENT_ID","");
                this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
                this.$router.push({ name: "home" }).catch({}); 
            }else{
                this.$store.commit(this.state+"/SET_PARENT_ID",list.file.parent_id);
                // this.$store.commit(this.state+"/SET_BREADCUMB",list.parent_folder);
                this.$store.dispatch(this.state+"/listing")
                this.$router.push({ name: "home" }).catch({}); 
            }
        },
        open(list){
            if(list.file.encrypt == null || (list.file.encrypt.code != 2 && list.file.encrypt.code != 4 && list.file.encrypt.code != 7)){
                this.openFile(list)
            } else {
                if(this.canAccess){
                    this.sendInfoDecrypt(list)
                }
            }
        },
        openFile(list){
            if(Helper.supportOnlyOffice(list.file.extension)){
                if(this.canAccess){
                    localStorage.setItem("access_type", JSON.stringify("edit"))
                }else{
                    localStorage.setItem("access_type", JSON.stringify("view"))
                }
                var payloadFile = {
                    _id: list.file.id,
                    directory: list.file.directory
                }
                this.$store.dispatch("storage/open",payloadFile);
            }else if(Helper.isImages(list.file.extension)){
                var payloadImg = {
                    _id: list.file.id,
                    directory: list.file.directory
                }
                this.$store.dispatch("storage/getLinkFile",payloadImg);
                this.$store.dispatch("storage/fileretrieve",{_id : list.file.id,directory:list.file.directory});
                    let imageView = document.getElementById("imageView");
                    imageView.style.display = 'block';
            }else if(Helper.isVideos(list.file.extension)){
                this.$store.dispatch("storage/fileretrieve",{_id : list.file.id,directory:list.file.directory})
                .then(c=>{
                    let video = document.getElementById("VideoView");
                    video.style.display = 'block';
                    this.$store.dispatch("storage/getLinkFile",c)
                    .then((v)=>{
                    let videoPlayer = document.getElementById("videoPlayer");
                    videoPlayer.src = v;
                    videoPlayer.play();
                    });
                });
            }else if(Helper.isAudios(list.file.extension)){
                this.$store.dispatch("storage/fileretrieve",{_id : list.file.id,directory:list.file.directory})
                .then(c=>{
                    let audio = document.getElementById("AudioView");
                    audio.style.display = 'block';
                    this.$store.dispatch("storage/getLinkFile",c)
                    .then((v)=>{
                    let audioPlayer = document.getElementById("audioPlayer");
                    audioPlayer.src = v;
                    audioPlayer.play();
                    });
                });
            }else{
                this.$store.dispatch("storage/download",
                {
                    directory : list.file.directory,
                    hash_name : list.file.hash_name,
                    file_name : list.file.file_name
                }
                );
            }
        },
        deleteChat(data){
            this.$store.commit('chat/SET_DELETE_MESSAGE', data)
            this.$root.$emit('bv::show::modal', 'modal-delete-message')
        },
        getImage(filename){
            var storageUtils = new Storage();
            // return storageUtils.getImage(filename.substr(filename.lastIndexOf('.') + 1))
            return storageUtils.getImage(filename)
        },
    },
    mounted(){
        this.$root.$on('bv::dropdown::show', bvEvent => {
            if(bvEvent.componentId === 'sub-files-media-dropdown') {
                this.isSubMediaVisible = true;
            }
        })
        this.$root.$on('bv::dropdown::hide', bvEvent => {
            if(bvEvent.componentId === 'sub-files-media-dropdown') {
                this.isSubMediaVisible = false;
            }
            if(this.isSubMediaVisible) {
                bvEvent.preventDefault()
            }
        })
        this.$root.$on('bv::dropdown::show', bvEvent => {
            if(bvEvent.componentId === 'sub-files-docs-dropdown') {
                this.isSubDocsVisible = true;
            }
        })
        this.$root.$on('bv::dropdown::hide', bvEvent => {
            if(bvEvent.componentId === 'sub-files-docs-dropdown') {
                this.isSubDocsVisible = false;
            }
            if(this.isSubDocsVisible) {
                bvEvent.preventDefault()
            }
        })
        this.$root.$on('bv::dropdown::show', bvEvent => {
            if(bvEvent.componentId === 'sub-files-links-dropdown') {
                this.isSubLinksVisible = true;
            }
        })
        this.$root.$on('bv::dropdown::hide', bvEvent => {
            if(bvEvent.componentId === 'sub-files-links-dropdown') {
                this.isSubLinksVisible = false;
            }
            if(this.isSubLinksVisible) {
                bvEvent.preventDefault()
            }
        })
    }

}
</script>

<style>

</style>