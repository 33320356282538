<script>
export default {
    data() {
        return {
            invalidTitle: "Please Fill the Field !",
            stateTitle : true,
            formState : [
                {
                    id : "ABOUT",
                    subtitle : "Workgroup are where your team communicate. They’re best when organized around a topic.",
                    active : true
                },
                {
                    id : "PARTICIPANTS",
                    subtitle : "e.g. example@jaybod.com",
                    active : false
                }
            ],
            emailuser : "",
            accessTypes : [
                {
                    id : "View",
                    label : "Viewer",
                    selected : true
                },
                {
                    id : "Write",
                    label : "Editor",
                    selected : false
                }
            ],
            validEmail : true,
            participants : []
        }
    },
    computed: {
        getFormState(){
            return this.formState.filter((v)=>v.active)[0];
        },
        selectedAccess(){
            return this.accessTypes.filter(v=>v.selected)[0]
        },
        loading(){
            return this.$store.getters["workgroup/loading"];
        },
        title: {
        // getter
            get: function () {
                return this.$store.getters['workgroup/title'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_TITLE",newValue);
            }
        },
        description: {
        // getter
            get: function () {
                return this.$store.getters['workgroup/description'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_DESCRIPTION",newValue);
            }
        },
        fullname() {
            return this.$store.getters['user/fullname']
        },
    },
    methods:{
        close(){
            this.$root.$emit('bv::hide::modal', 'createWorkgroup');
        },
        selectAccestype(index){
            this.accessTypes = this.accessTypes.map((v,i)=>{
                return {label:v.label,selected:i==index,id:v.id}
            })
        },
        next(){
            this.stateTitle = this.title != ''
            if(this.stateTitle){
                this.formState[0].active = false;
                this.formState[1].active = true;
            }
        },
        back(){
            this.formState[0].active = true;
            this.formState[1].active = false;
        },
        onEnter(){
            if(this.validateEmail(this.emailuser)){
                var exists = this.participants.filter((v)=>v.email == this.emailuser);
                if(exists.length == 0){
                    this.participants.push(
                        {
                            email : this.emailuser,
                            access_type : this.selectedAccess
                        }
                    )
                }
            }
        },
        changeAccess(access,index){
            this.participants[index].access_type = access;
        },
        create(){
            this.$ga.event('create_wg', 'actionx', 'labelx', 1)
            var param = {
                title : this.title,
                description : this.description,
                participant : this.participants.map((v)=>{
                    return {
                        email:v.email,
                        access_type : v.access_type.id
                    }
                })
            }
            this.$store.dispatch("workgroup/create",param).then(()=>{
                this.participants = []
                this.title = "";
                this.description = "";
                this.formState = [
                    {
                        id : "ABOUT",
                        subtitle : "Workgroup are where your team communicate. They’re best when organized around a topic.",
                        active : true
                    },
                    {
                        id : "PARTICIPANTS",
                        subtitle : "e.g. example@jaybod.com",
                        active : false
                    }
                ];
            });
            this.$root.$emit('bv::hide::modal', 'createWorkgroup');
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        remove(email){
            this.participants = this.participants.filter((v)=>v.email != email);
        }
    }
};
</script>
<template>
    <b-modal id="createWorkgroup"  no-fade modal-sm no-stacking  content-class="shadow" :hide-header="true" :hide-footer="true"  centered >
        <div class="header p-2">
            <div class="d-flex justify-content-between align-items-center p-3">
                <span class="default-text-style bold-normal text-dark">Create a Workgroup ✨</span>
                <span class="cursor-pointer px-2" @click="close()">
                    <img src="@/assets/images/icon/Close.png" alt width="100%" />
                </span>
            </div>
            <div class="sub mx-3">
                <p class="default-text-style"><span class="text-muted">{{getFormState.subtitle}}</span></p>
                <div class="d-flex">
                    <div class="d-flex align-items-center breadcumb">
                        <p class="text-left pt-3 default-text-style"><b>All Files</b></p>
                        <i class=" bx bx-chevron-right mx-1 font-size-18" style="padding-top:4.3px">  </i>
                    </div> 
                    <div class="d-flex align-items-center breadcumb">
                        <p class="text-left pt-3 default-text-style"><b :class="getFormState.id != 'ABOUT' ? '' : 'text-muted'">Participant</b></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="about" v-if="getFormState.id == 'ABOUT'">
            <div class="mx-3 mb-4 px-3">
                <b-form-group id="title" :invalid-feedback="invalidTitle" :state="stateTitle" class="mt-4">
                    <b-form-input id="firstname"  class="login-input-style" autocomplete="false" placeholder="e.g. Plan Budget" v-model="title"  trim></b-form-input>
                </b-form-group>
                <b-form-group id="description" class="mt-4">
                    <b-form-textarea id="textarea-rows"  class="login-input-style" placeholder="Description (Optional) "  v-model="description"  rows="4"></b-form-textarea>
                </b-form-group>
            </div>
            <div class="my-2 mx-3 py-4 px-3 d-flex justify-content-end align-items-center">
                <button class="btn base-btn btn-storage" @click="next()">
                    <span class="default-text-style">Next</span>
                </button>
            </div>
        </div>
        <div class="participants" v-else>
            <div class="mx-3">
                <b-form-group id="input-group-1" class="pt-4">
                    <b-input-group>
                        <b-form-input id="input-1" v-on:keyup.enter="onEnter" v-model="emailuser" class="login-input-style border-right-none"  type="email" placeholder="Email"></b-form-input>
                        <template #append>
                            <b-dropdown class="dropdown-input" right>
                                <template #button-content>
                                    <span class="text-blue default-text-style"><span class="text-blue">{{selectedAccess.label}}</span></span>
                                    <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                                </template>
                                <b-dropdown-item v-for="(access,index) in accessTypes" :key="index" @click="selectAccestype(index)">
                                    <i v-if="access.selected" class="dripicons-checkmark px-2  text-blue"></i>
                                    <span v-else class="px-3"></span>
                                    <span class="text-dark default-text-style">{{access.label}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>                
                    </b-input-group>
                    <b-form-invalid-feedback :state="validEmail">
                        Your Email Invalid Format
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
            <div class="mx-3 px-2">
                <div class="d-flex justify-content-between align-items-center py-2">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="avatar-xs">
                            <span class="avatar-title rounded-circle">{{fullname.charAt(0).toUpperCase()}}</span>
                        </div>
                        <span class=" default-text-style text-dark px-2">{{fullname}}</span>
                    </div>
                    <span class=" default-text-style text-dark">Owner</span>
                </div>
                <div class="d-flex justify-content-between align-items-center py-2" v-for="(participant,index) in participants" :key="index">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="avatar-xs">
                            <span class="avatar-title rounded-circle">{{participant.email.charAt(0).toUpperCase()}}</span>
                        </div>
                        <span class=" default-text-style text-dark px-2">{{participant.email}}</span>
                    </div>
                    <b-dropdown class="dropdown-input border-none" left>
                        <template #button-content>
                            <span class="text-dark default-text-style ">{{participant.access_type.label}}</span>
                            <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                        </template>
                        <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="changeAccess(access,index)">
                            <i v-if="access.label == participant.access_type.label" class="dripicons-checkmark px-2  text-blue"></i>
                            <span v-else class="px-3"></span>
                            <span class="text-dark">{{access.label}}</span>
                        </b-dropdown-item>
                        <hr class="mx-2 mt-1 mb-1">
                        <b-dropdown-item @click="remove(participant.email)">
                            <!-- <i class="dripicons-checkmark px-2  text-blue"></i> -->
                            <span class="text-danger">remove</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <br><br><br>
            <div class="my-2 mx-3 px-3 py-4 d-flex justify-content-between align-items-center">
                <a href="javascript:void(0);" @click="back()" class="default-text-style"><span class="text-blue">Back</span></a>
                <button  class="btn base-btn bg-blue btn-storage"  :disabled="loading" @click="create()">      
                    <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>              
                    <span class="default-text-style">{{participants.length == 0 ? 'Skip For Now' : 'Send Invitations'}}</span>
                </button>
            </div>
        </div>
    </b-modal>
</template>