<script>
export default {
    data() {
        return {
        };
    },
    computed: {
        breadcumb() {
            return this.$store.getters[this.$store.getters['state/state']+'/breadcumbs']
        },
        item() {
            return this.$store.getters['share/item']
        },
        owner() {
            return this.$store.getters['user/fullname']
        },
        state(){
            return this.$store.getters['state/state'];
        }
    },
    methods:{
      upperCase(str){
        let strIndex = str.split('')
        let arr = [0]
        for(let i = 0; i < arr.length; i++){
          if(strIndex[arr[i]]){
              strIndex[arr[i]] = strIndex[arr[i]].toUpperCase()
          }
        }
        return strIndex.join('')
      },
      close(){
        this.$store.commit("share/SET_SHOW_PROPERTIES",false);
        // var header = document.getElementById("page-topbar");
        // header.style.right = "0"; 
        // var mainpage = document.getElementById("main-page");
        // mainpage.style.marginRight = "10px"; 
      },
    },
    mounted(){
        this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
        this.$store.commit("share/SET_SHOW_PROPERTIES",false);
    }
};
</script>
<template>
    <div>
    <!-- <pre>{{item}}</pre> -->
        <div v-if="item == null">
            <div class="empty-item-sideRight mt-2">
                <div class="empty-details-information mt-3">
                    <img src="@/assets/images/empty-information.svg" alt="">
                    <span class="mt-2">Select a folder or file to see the</span>
                    <span class="">information.</span>
                </div>
            </div>
        </div>
        <div v-if="item != null">
            <div class="img-info-sideRight mt-2">
                <!-- <pre>{{item}}</pre> -->
                <img v-if="item.file[0].encrypt == 2 || item.file[0].encrypt == 7" src="@/assets/images/icon/lock-encrypt.svg" alt=""/>
                <img v-else :src="require(`@/assets/images/icon/${item.file[0].icon}`)" alt=""/>
            </div>
            <div class="detail-item-sideRight mt-5 row">
                <div class="key-detail-item-sideRight col-5">
                    <p class="mt-4">Type</p>
                    <p class="mt-4">Location</p>
                    <p class="mt-4">Owner</p>
                    <p class="mt-4">Modified</p>
                    <p class="mt-4">Created</p>
                </div>
                <div class="target-detail-item-sideRight col-7">
                    <p class="mt-4"><span>{{item.file[0].isFolder ? 'Folder' : item.file[0].extension}}</span></p>
                    <p class="mt-4">
                        <span v-if="breadcumb.length < 1">Shared</span>
                        <!-- <span v-if="breadcumb.length < 1">{{state == 'storage' ? 'All Files' : 'Group Files'}}</span> -->
                        <!-- <span v-if="breadcumb.length > 0">{{breadcumb[breadcumb.length-1].file_name.substring(0,15)}}{{breadcumb[breadcumb.length-1].file_name.length > 15 ? '...':''}}</span> -->
                        <!-- <span v-if="breadcumb.length > 0">Shared With Me</span> -->
                        <span v-if="breadcumb.length > 0">Shared</span>
                    </p>
                    <p class="file-name-details mt-4"><span>{{upperCase(item.users ? item.users.firstname : '-')}} {{upperCase(item.users ? item.users.lastname : '')}}</span></p>
                    <p class="mt-4"><span>{{item.file[0].datemodified}}</span></p>
                    <p class="mt-4"><span>{{item.file[0].datecreated}}</span></p>
                </div>
            </div>
        </div>
    </div>
</template>