<script>
import { required } from 'vuelidate/lib/validators'

export default {
    props: {
        filetype: {
            type: String,
            default: ".txt"
        }
    },
    validations: {
        folderName: {
            required
        }
    },
    computed:{
        item(){
            return this.$store.getters[this.$store.getters["state/state"]+"/item"];
        },
        loading(){
            return this.$store.getters[this.$store.getters["state/state"]+"/loading"];
        },
        parent_id(){
            return this.$store.getters[this.$store.getters["state/state"]+"/parent_id"];
        },
        state(){
            return this.$store.getters["state/state"];
        }
    },
    data() {
        return {
            folderName: "New File",
            invalidFolderName : "",
            validFolderName : "",
            stateFolderName : true,
            counter : 0
        };
    },
    // mounted(){
    //     this.stateFolderName = false;
    // },
    watch: {
        folderName() {
            if(this.folderName == '' || this.folderName == null){
                this.invalidFolderName = ''
                this.stateFolderName = true
            }
        }
    },
    methods:{
        validationStatus(validation) {
            return typeof validation != undefined ? validation.$error: false
        },
        close(){
            this.stateFolderName = true
            this.$store.commit("error/SET_ERROR", false)
            this.$store.commit("error/SET_MESSAGE", '')
            this.$root.$emit('bv::hide::modal', 'modalCreate');
        },
        submitEvent() {
            localStorage.setItem("access_type", JSON.stringify("edit"))
            this.stateFolderName = true;
            return (
                this.$store.dispatch(this.$store.getters["state/state"]+"/createFile",{
                    file_name: this.folderName,
                    parent_id : this.parent_id,
                    extension : this.filetype.substring(1)
                })
                // eslint-disable-next-line no-unused-vars
                .then(token => {
                    this.validFolderName = token;
                    this.stateFolderName = true;
                    this.folderName = "New File";
                    this.$store.dispatch(this.state+"/listing","noclear")
                    this.$root.$emit('bv::hide::modal', 'modalCreate')
                })
                .catch(error => {
                    this.folderName = "New File";
                    this.invalidFolderName = error ? error.data.display_message : "";
                    this.stateFolderName = false;
                })
            );
        }
    }
};
</script>
<style scoped>
.form-input-name-file{
    position: relative !important;
}
.form-input-name-file .file-type{
    position: absolute !important;
    top: 18px !important;
    right: 16px !important;
}
.file-type{
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
}
</style>

<template>
    <b-modal id="modalCreate" no-fade  content-class="shadow" :hide-header="true"  :hide-footer="true"  centered title="Create File">
        <h1 class="h1-title default-text-style bold-normal">Create New File</h1>
        <b-form action="" @submit.prevent="submitEvent">
            <div class="form-input">
              <!-- <b-form-group > -->
                <b-form-input
                  v-model.trim="$v.folderName.$model"
                  type="text"
                  class="form-control input-box-v3"
                  :class="{'is-invalid': validationStatus($v.folderName) || (!stateFolderName)}"
                  placeholder="File name"></b-form-input>
                  <div class="file-types-extension">{{ filetype }}</div>
                  <div class="invalid-feedback">
                    <span v-if="!$v.folderName.required" id="email-empty-fp" class="required-color error-message-v3">File name cannot be empty.</span>
                    <span v-else-if="invalidFolderName != ''" class="required-color error-message-v3">{{ invalidFolderName }}</span>
                  </div>
              <!-- </b-form-group> -->
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <span @click="close()" class="default-normal-size main-color-api mr-3 cursor-pointer">Cancel</span>
                <button class="create-item-btn-v3 ml-3" :disabled="$v.$invalid">
                    <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                    Create
                </button>
            </div>
        </b-form>
        <!-- <b-form class="p-2" @submit.prevent="submitEvent">
            <b-form-group :invalid-feedback="invalidFolderName" :state="stateFolderName" id="input-group-1" class="py-2" label-class="label-input" label-for="input-1">
                <div class="form-input-name-file">
                    <b-form-input 
                        id="input-1"
                        required
                        v-model="folderName"
                        class="input-box-v3"
                        type="text"
                        placeholder="File New"></b-form-input>
                    <div class="file-type">
                        <span class="text-blue">{{filetype}}</span>
                    </div>
                </div>
            </b-form-group>
            <div class="mt-2 pt-2 d-flex justify-content-between align-items-center">
                <a @click="close" class="default-text-style"><span class="text-blue">Cancel</span></a>
                <button class="btn base-btn btn-storage" :disabled="loading">
                    <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> 
                    <span class="default-text-style">Create</span>
                </button>
            </div>
        </b-form> -->
    </b-modal>
</template>