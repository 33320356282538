<template>
    <b-modal id="modal-alert-create-wg-v3" no-close-on-backdrop hide-header hide-footer centered>
        <div class="container-alert-create-wg">
            <span class="title-alert-wg-v3 mb-3">Discard workgroup?</span>
            <span class="sub-title-alert-wg-v3">Your workgroup has not been created yet.</span>
            <div class="d-flex justify-content-end align-items-center">
                <button class="mr-2 cancel-mcreate-btn-v3" @click="cancel()">Cancel</button>
                <button class="mcreate-btn-v3" @click="discard()">Discard</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    computed: {
        title: {
            // getter
            get: function () {
                return this.$store.getters['workgroup/title'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_TITLE",newValue);
            }
        },
        description: {
            cache: false,
            // getter
            get: function () {
                return this.$store.getters['workgroup/description'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_DESCRIPTION",newValue);
            }
        },
    },
    methods: {
        discard() {
            this.$ga.event(process.env.VUE_APP_GA_CREATE_WORKGROUP_BTN_CANCEL, 'Create WG - Click Button Cancel', 'wg-action', 1)
            this.title = null
            this.description = ''
            this.$root.$emit('bv::hide::modal', 'modal-alert-create-wg-v3')
        },
        cancel() {
            this.$root.$emit('bv::hide::modal', 'modal-alert-create-wg-v3')
            this.$root.$emit('bv::show::modal', 'modal-create-wg-v3')
        },
    }
}
</script>

<style>
.container-alert-create-wg{
    display: flex;
    flex-direction: column;
}
.title-alert-wg-v3{
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1px;
    color: #262A2C;
}
.sub-title-alert-wg-v3{
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: -1px;
    color: #262A2C;
}
</style>