<template>
    <b-modal id="modal-create-wg-v3" no-close-on-backdrop hide-header hide-footer centered>
        <div class="container-modal-create-wg">
            <span class="title-invite-wg-v3 mb-3">Create a workgroup </span>
            <span class="sub-title-invite-wg-v3">Use workgroup to store, organize, and collaborate on files together.</span>
            <b-form-group id="title" :invalid-feedback="invalidTitle" :state="stateTitle" class="">
                <b-form-input
                    id="firstname"
                    class="input-modal-create input-modal-create-title"
                    :class="!stateTitle ? 'border-red-err-v3' : ''"
                    autocomplete="false"
                    placeholder="Workgroup name"
                    v-model="title" trim></b-form-input>
                <!-- <div class="invalid-feedback">
                    <span v-if="!$v.title.required" class="required-color error-message-v3">Title workgroup is required.</span>
                </div> -->
            </b-form-group>
            <b-form-group id="description" class="">
                <b-form-textarea
                    id="textarea-rows"
                    class="input-modal-create input-modal-create-desc"
                    :class="{'is-invalid': validationStatus($v.description)}"
                    placeholder="Description (Optional) "
                    v-model.trim="$v.description.$model"
                    rows="4"></b-form-textarea>
                <div class="invalid-feedback">
                    <span v-if="!$v.description.maxLength" class="required-color error-message-v3">Description max. length is 255 characters.</span>
                </div>
            </b-form-group>
            <b-dropdown id="dropdown-modal-select-type-wg">
                <template #button-content>
                    <div class="d-flex align-items-center justify-content-between">
                        <span :class="selectedAccess.id == null ? 'title-invite-selec-wg-v3' : 'title-invite-wg-v3'">{{ selectedAccess.label }}</span>
                        <img src="@/assets/images/icon/chevDown.svg" alt="">
                    </div>
                </template>
                <b-dropdown-item v-for="(access,ia) in filteredAccessTypes" :key="ia" @click="changeAccess(ia)">
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-start">
                            <img v-if="access.selected" src="@/assets/images/icon/Checklist.svg" alt="">
                            <span v-else class="mx-2"></span>
                                <span class="title-invite-wg-v3 mx-3">{{ access.label }}</span>
                        </div>
                        <div class="d-flex justify-content-start">
                            <span class="mx-2"></span>
                                <span class="sub-invite-wg-v3 mx-3">{{ access.info }}</span>
                        </div>
                    </div>
                    <!-- <div class="d-flex justify-content-start">
                        <img v-if="access.selected" src="@/assets/images/icon/Checklist.svg" alt="">
                        <span v-else class="mx-2"></span>
                        <div class="d-flex flex-column">
                            <span class="title-invite-wg-v3 mx-3">{{ access.label }}</span>
                            <span class="title-invite-wg-v3 mx-3">{{ access.info }}</span>
                        </div>
                    </div> -->
                </b-dropdown-item>
            </b-dropdown>
            <div class="d-flex justify-content-end align-items-center mt-36px">
                <button class="mr-2 cancel-mcreate-btn-v3" @click="cancel()">Cancel</button>
                <button :disabled="$v.$invalid || selectedAccess.id == null" class="mcreate-btn-v3" @click="next()">Create</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            invalidTitle: 'Please Fill the Field !',
            stateTitle: true,
            accessTypes : [
                {
                    id : null,
                    label : "Type of workgroup",
                    info : null,
                    selected : true
                },
                {
                    id : "STANDARD",
                    label : "Standard",
                    info : "Files are accessible to all participants, according to their roles.",
                    selected : false
                },
                {
                    id : "PRIVATE",
                    label : "Private",
                    info : "Files are accessible only to the uploader and workgroup owner.",
                    selected : false
                }
            ],
        }
    },
    computed: {
        title: {
            // getter
            get: function () {
                return this.$store.getters['workgroup/title'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_TITLE",newValue);
            }
        },
        description: {
            // getter
            get: function () {
                return this.$store.getters['workgroup/description'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit("workgroup/SET_DESCRIPTION",newValue);
            }
        },
        filteredAccessTypes: {
            get: function() {
                return this.accessTypes.filter(v=>v.info != null)
            }
        },
        selectedAccess(){
            return this.accessTypes.filter(v=>v.selected)[0]
        }
    },
    validations: {
        // title: {
        //     required
        // },
        description: {
            maxLength: maxLength(255)
        }
    },
    methods: {
        changeAccess(idx){
            this.$ga.event(process.env.VUE_APP_GA_CREATE_WORKGROUP_CHANGE_TYPE, 'Create WG - Change Workgroup Type', 'wg-action', 1)
            this.accessTypes = this.filteredAccessTypes.map((v,i)=>{
                return {
                    label: v.label,
                    selected: i == idx,
                    info: v.info,
                    id: v.id
                }
            })
        },
        validationStatus(validation) {
            return typeof validation != undefined ? validation.$error: false
        },
        cancel() {
            this.accessTypes = [
                {
                    id : null,
                    label : "Type of workgroup",
                    info : null,
                    selected : true
                },
                {
                    id : "STANDARD",
                    label : "Standard",
                    info : "Files are accessible to all participants, according to their roles.",
                    selected : false
                },
                {
                    id : "PRIVATE",
                    label : "Private",
                    info : "Files are accessible only to the uploader and workgroup owner.",
                    selected : false
                }
            ]
            this.$root.$emit('bv::show::modal', 'modal-alert-create-wg-v3')
            this.$root.$emit('bv::hide::modal', 'modal-create-wg-v3')
        },
        next() {
            this.$ga.event(process.env.VUE_APP_GA_CREATE_WORKGROUP_BTN_CREATE, 'Create WG - Click Button Create', 'wg-action', 1)
            if(this.title == '') {
                this.stateTitle = false
            } else {
                this.stateTitle = true
                var params = {
                    title: this.title,
                    description: this.description,
                    type: this.selectedAccess.id,
                    participant: []
                }
                this.$store.commit("storage/SET_ALL_SELECTED",[]);
                this.$store.dispatch("workgroup/create",params)
                .then((res) => {
                    this.$store.dispatch("workgroup/workgroupList")
                    .then((items)=>{
                        let setWorkgroup = items.filter(item => item._id === res.data[0]._id)
                        this.$store.commit("workgroup/SET_WORKGROUP", setWorkgroup[0])
                        
                        this.$router.push({ path: '/', query: {wgId: res.data[0]._id}})
                        
                        .then(()=>{
                            this.$store.dispatch('storage/setSidebarDisplay', true)
                            this.$toasted.show('Workgroup created successfully.',{ 
                                theme: "toasted-primary", 
                                position: "bottom-center", 
                                duration : 2000
                            })
                            this.$store.dispatch("chat/sortingParticipant",null,{root:true})
                            this.$root.$emit('bv::show::modal', 'modal-add-participant-v3')
                            this.$root.$emit('bv::hide::modal', 'modal-create-wg-v3')
                        })
                        this.$ga.event(process.env.VUE_APP_GA_CREATE_WORKGROUP_BTN_INVITE, 'Create WG - Add Participants', 'wg-action', 1)
                        this.title = null
                        this.description = ''
                    })
                })
                .catch((err) => {
                    this.$toasted.show('Workgroup failed to created.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                })
            }
        }
    }
}
</script>

<style>
#dropdown-modal-select-type-wg{
    width: fit-content;
    min-width: 100%;
    padding: 0 16px;
    border: 1px solid #ced4da !important;
    border-radius: 4px;
    height: 52px;
}
#dropdown-modal-select-type-wg button{
    background: #FFFFFF !important;
    color: #262A2C !important;
    border: none;
    margin: 0;
    padding: 0;
}
#dropdown-modal-select-type-wg ul li a{
    padding: 3px 3px 3px 20px;
}
.title-invite-selec-wg-v3{
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 400;
    color: #919394;
    line-height: 20px;
    letter-spacing: -0.02em;
}
.sub-invite-wg-v3{
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    color: #919394;
    line-height: 16px;
    letter-spacing: -0.02em;
    word-break: break-word;
    white-space: normal;
}
.container-modal-create-wg{
    display: flex;
    flex-direction: column;
}
.input-modal-create:focus{
    border: 2px solid #00AAFF;
}
.input-modal-create{
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: -1px;
    color: #262A2C;
    padding: 16px;
}
.input-modal-create::placeholder{
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: -1px;
    color: #919394;
}
.input-modal-create-title{
    height: 52px;
    width: 384px;
}
.input-modal-create-desc{
    height: 152px !important;
}
.cancel-mcreate-btn-v3:focus{
    background: none;
}
.border-red-err-v3{
    border: 1px solid #D42020;
}
.mt-36px{
    margin-top: 36px;
}
</style>