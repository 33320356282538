<script>
import detailWorkgroup from "./detail.vue";
import deleteParticipants from "./deleteParcipant.vue";
export default {
    data() {
        return {
            addParticipantState : false
        };
    },
    components:{detailWorkgroup,deleteParticipants},
    computed: {
        listgroups(){
            var list =  this.$store.getters['chat/listgroups']
            var workgroup = this.$store.getters['workgroup/workgroup']
            list = list.filter((v)=>v.roomId == workgroup._id)
            return list
        },
        currentRouteName () {
            return this.$route.name
        },
        owner() {
            return this.$store.getters['user/fullname']
        },
        id() {
            return this.$store.getters['user/id']
        },
        userChat(){
            var users = this.$store.getters['chat/sortParticipant']
            var userOnline = users.filter(user => user.online == true)
            return userOnline
        },
        participants(){
            var users = this.$store.getters['chat/sortParticipant']
            return users
        },
        item() {
            return this.$store.getters['workgroup/workgroup']
        },
    },
    methods:{
        getRandomNumber(){
            return Math.floor(Math.random() * 8) + 1;
        },
        close(){
            this.$store.dispatch("workgroup/hideDetail");
        },
        detail(){
            this.$store.commit("workgroup/SET_TITLE",this.item.title);
            this.$store.commit("workgroup/SET_DESCRIPTION",this.item.description);
            this.$root.$emit('bv::show::modal', 'detailWorkgroup');
        }
    },
    mounted() {
        this.$store.dispatch('chat/openChat')
        this.$store.getters['chat/sortParticipant']
    }
};
</script>
<style scoped>
.status-online{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #009934;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
}
.status-offline{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #D42020;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
}
</style>
<template>
    <div class="mt-3">
        <detailWorkgroup/>
        <deleteParticipants/>
        <div class="list-participant-sideRight" v-for="(participant,index) in item.participant" :key="index">
        <!-- {{participant.status}} -->
            <div v-if="participant.status === 3 || participant.status === '3'" class="users-participant-sideRight mb-2">
                <div class="avatar-xs">
                    <span class="avatar-title rounded-circle">{{participant.email.charAt(0).toUpperCase()}}</span>
                    <span :class="participant.online == true ? 'status-online' : 'status-offline'"></span>
                </div>
                <span class=" default-text-style text-dark px-2">{{participant.firts_name}} {{participant.lastname}} <span class="text-muted">{{id == participant._id ? "(You)" : ""}}</span></span>
            </div>
        </div>
    </div>
</template>