<script>

import moment from 'moment';
export default {
    data() {
        return {
            invalidTitle: "Please Fill the Field !",
            keyword : "",
            tab : "ABOUT",
            accessTypes : [
                {
                    id : "View",
                    label : "View",
                    selected : true
                },
                {
                    id : "Write",
                    label : "Write",
                    selected : false
                }
            ],
        }
    },
    computed: {
        loading(){
            return this.$store.getters["workgroup/loading"];
        },
        packageUser(){
            return this.$store.getters["user/package"];
        },
        workList() {
            return this.$store.getters['workgroup/workgroups']
        },
        workgroup() {
            return this.$store.getters['workgroup/workgroup']
        },
        _id() {
            return this.$store.getters['user/id']
        },
        isOwner(){
            var id = this.$store.getters['user/id'];
            var group = this.$store.getters['workgroup/workgroup'];
            return id == group.owner_id;
        }
    },
    mounted(){
    },
    methods:{
        close(){
            this.$root.$emit('bv::hide::modal', 'workgroupList');
        },
        changeTab(value){
            this.tab = value;
        },
        setSelected(item){
            this.$store.dispatch("workgroup/retrieve",item._id)
            .then(()=>{
                this.$store.dispatch("workgroup/showDetails");
            });
        },
        getDate(date){
            return moment(date).format('LL');
        },
        create(){
            this.$root.$emit('bv::show::modal', 'createWorkgroup');
        },
        deleteForever(){
            this.$root.$emit('bv::show::modal', 'modalDeleteWorkgroup');
        }
    }
};
</script>
<template>
    <b-modal id="workgroupList"  no-fade modal-sm no-stacking size="lg"  content-class="shadow" :hide-header="true" :hide-footer="true"  centered >
        
        <div class="header">
            <div class="row">
                <div class="col-md-4">
                    <div class="p-4">
                        <span class="default-text-style bold-normal text-dark font-size-16">Workgroup List 🤝</span>
                        <div class="my-4 "></div>
                        <button v-if="packageUser.workgroup.max_workgroup > workList.length"  class="btn base-btn bg-blue btn-storage"  :disabled="loading" @click="create()">      
                            <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>              
                            <span class="default-text-style"> <i class="bx bx-plus"></i> Workgroup</span>
                        </button>
                        <router-link v-if="packageUser.workgroup.max_workgroup <= workList.length" tag="a" to="/packages" class="btn base-btn bg-blue btn-storage">             
                            <span class="default-text-style"> <i class="bx bxs-cart"></i> Upgrade</span>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="pr-2 pt-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <img src="@/assets/images/icon/workgroup.png" alt  />
                                <span class="mx-2 wraptext default-text-style bold-normal  font-size-16"> {{workgroup.title}}</span>
                            </div>
                            <span class="cursor-pointer px-2" @click="close()">
                                <img src="@/assets/images/icon/Close.png" alt width="100%" />
                            </span>
                        </div>
                        <div class="my-3 pt-2"></div> 
                        <div class="custom-tabs">
                            <span @click="changeTab('ABOUT')" :class="`default-text-style bold-normal font-size-16 pb-2 mx-2 border-bottom ${tab == 'ABOUT' ? 'active' : ''}`">About</span>
                            <span @click="changeTab('PARTICIPANTS')" :class="`default-text-style bold-normal font-size-16 pb-2 mx-2 border-bottom ${tab != 'ABOUT' ? 'active' : ''}`">Participants ({{workgroup.participant.length}})</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 pr-0">
                <div class="p-3">
                    <form class="app-search pb-0 pt-1 d-none d-lg-block" >
                        <div class="position-relative">
                            <input type="search" v-model="keyword" class="form-control  login-input-style"  placeholder="Search..." />
                            <span class="bx bx-search-alt"></span>
                        </div>
                    </form>
                </div>
                <b-list-group class="my-3 pl-3">
                    <b-list-group-item button  v-for="(work,index) in workList" :key="index" @click="setSelected(work)" :active="workgroup._id == work._id" >
                        <div class="d-flex align-items-center">
                            <img src="@/assets/images/icon/workgroup.png" alt  class="mb-4"/>
                            <span class="mx-2 wraptext default-text-style bold-normal  font-size-16">
                                <span class="active-color">{{work.title}}</span>
                                <p v-if="work.owner_id == _id" class="default-text-style-menu-bar font-size-12"><span class="text-muted">Owner Workgroup</span></p>
                                <p v-else class="default-text-style-menu-bar font-size-12"><span class="text-muted">Participant</span></p>
                                <!-- <pre>{{work}}</pre> -->
                            </span>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </div>
            <div class="col-md-8 border-left pl-0">
                <div class="p-3">
                    <div id="about-tab" v-if="tab == 'ABOUT'" >
                        <div class="pb-4 border-bottom d-flex flex-column align-content-start justify-content-start">
                            <span class="default-text-style font-size-12"><span class="text-muted">Workgroup Name</span></span>
                            <span class="wraptext default-text-style bold-normal  font-size-16"> {{workgroup.title}}</span>
                            <br>
                            <span class="default-text-style font-size-12"><span class="text-muted">Description</span></span>
                            <span class="wraptext default-text-style bold-normal  font-size-16">{{workgroup.description}}</span>
                            <br>
                            <span class="default-text-style font-size-12"><span class="text-muted">Created by</span></span>
                            <span class="wraptext default-text-style bold-normal  font-size-16"> {{workgroup.name}} on {{getDate(workgroup.datecreated)}}</span>
                            <br>
                        </div>
                        <br>
                        <!-- <button  class="btn base-btn btn-transparent">            
                            <span class="default-text-style"> <i class="bx bx-edit"></i> Edit About</span>
                        </button> -->
                        <button  class="btn base-btn btn-transparent" @click="deleteForever()" v-if="isOwner">            
                            <span class="default-text-style"> <span class="text-danger"><i class="bx bx-trash"></i>  Delete Workgroup</span></span>
                        </button>
                    </div>
                    <div id="participants-tab" v-else>
                        <div class="p-3">
                            <form class="app-search pb-0 pt-1 d-none d-lg-block" >
                                <div class="position-relative">
                                    <input type="search" v-model="keyword" class="form-control  login-input-style"  placeholder="Search..." />
                                    <span class="bx bx-search-alt"></span>
                                </div>
                            </form>
                            <br>
                            <div class="d-flex justify-content-between align-items-center py-2" v-for="(participant,windex) in workgroup.participant" :key="windex">
                                <div class="d-flex justify-content-start align-items-center">
                                    <div class="avatar-xs">
                                        <span class="avatar-title rounded-circle bg-red">{{participant.email.charAt(0).toUpperCase()}}</span>
                                    </div>
                                    <span class=" default-text-style text-dark px-2">{{participant.email}}</span>
                                </div>
                                <b-dropdown class="dropdown-input border-none" left  v-if="isOwner">
                                    <template #button-content>
                                        <span class="text-dark default-text-style ">{{participant.access_type}}</span>
                                        <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                                    </template>
                                    <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="changeAccess(access,index)">
                                        <i v-if="access.label == participant.access_type" class="dripicons-checkmark px-2  text-blue"></i>
                                        <span v-else class="px-3"></span>
                                        <span class="text-dark">{{access.label}}</span>
                                    </b-dropdown-item>
                                    <hr class="mx-2 mt-1 mb-1">
                                    <b-dropdown-item button @click="hapus(participant.email)">
                                        <!-- <i class="dripicons-checkmark px-2  text-blue"></i> -->
                                        <span class="text-danger">remove</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>