<template>
<div class="sidebar-right">
  <div id="side-bar-right" class="sideRight accordion">

    <!-- Name Item -->
    <div class="logo-sideRight">
      <!-- <p>{{selectedWorkgroup}}{{state}}</p> -->
      <div>
          <div class="text-sideRight hide-sideRight">Information</div>
      </div>
      <!-- <div v-if="sharedItem != null && showPropertiesShare">
          <div class="text-sideRight hide-sideRight">{{sharedItem.file[0].file_name.substring(0,27)}}{{sharedItem.file[0].file_name.length > 27 ? '...':''}}</div>
      </div>
      <div v-else-if="item == null">
        <div v-if="state == 'storage'">
          <div class="text-sideRight hide-sideRight">Personal</div>
        </div>
        <div v-else>
          <div class="text-sideRight hide-sideRight">Workgroup</div>
        </div>
      </div>
      <div v-else-if="item != null">
        <div class="text-sideRight hide-sideRight">{{item.file_name.substring(0,27)}}{{item.file_name.length > 27 ? '...':''}}</div>
      </div> -->
      <div class="showOffToggle" @click="sideRightToggle()">
        <img src="@/assets/images/icon/fi-rr-arrow-small-left-new.svg" alt="" v-b-tooltip.hover title="Information" id="logo-sideRight-hide">
      </div>
    </div>

    <!-- Details Item -->
    <div v-show="routeName != 'chat'" class="info-sideRight">
      <button class="title-info-sideRight" v-b-toggle.show-details-info-sideRight @click="sideRightToggle2()">
        <img src="@/assets/images/icon/fi-rr-info-new.svg" alt="" v-b-tooltip.hover title="Details" id="logo-sideRight-info">
        <div class="text-sideRight ml-2 hide-sideRight">Details</div>
      </button>
      <b-collapse accordion="my-accordiion-side-right" id="show-details-info-sideRight">
        <div v-if="showProperties == false && showPropertiesShare == false" class="empty-item-sideRight mt-2">
          <div class="empty-details-information mt-3">
            <img src="@/assets/images/empty-information.svg" alt="">
            <span class="mt-2">Select a folder or file to see the</span>
            <span class="">information.</span>
          </div>
        </div>
        <Properties v-show="showProperties"/>
        <PropertiesShared v-show="showPropertiesShare"/>
        <!-- <div v-if="item == null">
            <div class="empty-item-sideRight mt-2">
                <p>Select folder or files to see information</p>
            </div>
        </div>
        <div v-if="item != null">
            <div class="img-info-sideRight mt-2">
                <img :src="require(`@/assets/images/icon/lg/${item.icon}`)" alt=""/>
            </div>
            <div class="detail-item-sideRight mt-5 row">
                <div class="key-detail-item-sideRight col-5">
                    <p>Type</p>
                    <p>Location</p>
                    <p>Owner</p>
                    <p>Modified</p>
                    <p>Created</p>
                </div>
                <div class="target-detail-item-sideRight col-7">
                    <p><span>{{item.isFolder ? 'Folder' : item.extension}}</span></p>
                    <p><span>{{item.parent_id == "" ? "/" : item.parent_folder.file_name}}</span></p>
                    <p><span>Jaybod</span></p>
                    <p><span>{{item.datemodified}}</span></p>
                    <p><span>{{item.datecreated}}</span></p>
                </div>
            </div>
        </div> -->

        <!-- <div>
          <div v-if="item == null">
              <div class="empty-item-sideRight mt-2">
                  <p>Select folder or files to see information</p>
              </div>
          </div>
          <div v-if="item != null">
              <div class="img-info-sideRight mt-2">
                  <img :src="require(`@/assets/images/icon/lg/${item.icon}`)" alt=""/>
                  {{item}}
              </div>
              <div class="detail-item-sideRight mt-5 row">
                  <div class="key-detail-item-sideRight col-5">
                      <p>Type</p>
                      <p>Location</p>
                      <p>Owner</p>
                      <p>Modified</p>
                      <p>Created</p>
                  </div>
                  <div class="target-detail-item-sideRight col-7">
                      <p><span>{{item.isFolder ? 'Folder' : item.extension}}</span></p>
                      <p><span>{{item.parent_id == "" ? "/" : item.parent_folder.file_name}}</span></p>
                      <p><span>Jaybod</span></p>
                      <p><span>{{item.datemodified}}</span></p>
                      <p><span>{{item.datecreated}}</span></p>
                  </div>
              </div>
          </div>
        </div> -->
      </b-collapse>
    </div>

    <div v-show="routeName == 'chat'" class="info-sideRight">
      <button class="title-info-sideRight" v-b-toggle.show-files-in-chat @click="sideRightToggle2()">
        <img src="@/assets/images/icon/files-in-chat-logo.svg" alt="" id="logo-sideRight-info">
        <div class="text-sideRight ml-2 hide-sideRight">Files in chat</div>
      </button>
      <b-collapse accordion="my-accordiion-side-right" id="show-files-in-chat">
        <FilesInChat/>
      </b-collapse>
    </div>

    <!-- Shared -->
    <div v-show="selectedWorkgroup == null && state == 'storage'">
      <div class="info-sideRight">
        <button class="title-info-sideRight" v-b-toggle.show-shared-sideRight @click="sideRightToggle2()">
          <img src="@/assets/images/icon/fi-rr-cloud-share-new.svg" alt="" v-b-tooltip.hover title="Shared" id="logo-sideRight-shared">
          <div class="text-sideRight ml-2 hide-sideRight">Shared</div>
        </button>
        <b-collapse accordion="my-accordiion-side-right" id="show-shared-sideRight">
          <!-- {{routeName}} -->
          <div v-if="showProperties == false && showPropertiesShare == false" class="empty-item-sideRight mt-2">
            <p>Select a folder or file to see information</p>
          </div>
          <Shared v-show="showProperties"/>
          <SharedProperties v-show="showPropertiesShare"/>
          <!-- <p>aaaaaa</p> -->
          <!-- {{sharedItem}} -->
          <!-- <div class="empty-item-sideRight mt-2">
            <p>Select a folder or file to see information</p>
          </div>
          <div class="shared-info-sideRight">
            <div>
              <img src="@/assets/images/icon/lock-icon.png" alt="" id="img-not-shared-info-sideRight" class="mt-3">
              <div class="text-shared-info-sideRight mt-2">This item is not shared</div>
            </div>
            <div class="mt-3">
                <b-avatar class="mr-2" variant="primary" size="32px" text="A"></b-avatar>
                <b-avatar class="mr-2" variant="primary" size="32px" text="Z"></b-avatar>
            </div>
            <div class="text-shared-info-sideRight mt-2">This item is shared</div>
            <a href="#" class="text-manage-access-sideRight mt-2">Manage access</a>
          </div> -->
        </b-collapse>
      </div>
    </div>

    <!-- List Participants -->
    <div v-show="state != 'storage'">
      <div class="participant-sideRight">
        <div class="title-participant-sideRight">
          <div class="title-participant-sideRight-right"  >
            <button class="d-flex" v-b-toggle.show-participant-sidebarRight @click="sideRightToggle2()">
              <img src="@/assets/images/icon/fi-rr-users-new.svg" alt="" v-b-tooltip.hover title="Participant" id="logo-sideRight-participant" class="mt-1">
              <div class="text-sideRight-participant ml-2 mr-2 hide-sideRight">Participant</div>
            </button>
            <!-- <img src="@/assets/images/icon/fi-rr-info.png" alt="" id="logo-sideRight-participant-info" class="hide-sideRight ml-3" @click="detail()"> -->
          </div>
        </div>
        <b-collapse accordion="my-accordiion-side-right" id="show-participant-sidebarRight">
          <Participants/>
        </b-collapse>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Properties from '../components/widgets/properties.vue'
import Shared from '../components/widgets/shared2.vue'
import Participants from '../components/widgets/workgroup/participantList.vue'
import PropertiesShared from '../components/widgets/propertiesShare.vue'
import SharedProperties from '../components/widgets/sharedProperties.vue'
import FilesInChat from '../components/widgets/workgroup/filesInChat.vue'

export default {
  name: 'SidebarRight',
  data() {
    return {
      // checkedToggle: false
    }
  },
  components: {
    Properties,
    Shared,
    Participants,
    PropertiesShared,
    SharedProperties,
    FilesInChat
  },
  computed: {
    routeName(){
      return this.$route.name
    },
    checkedToggle() {
      return this.$store.getters["storage/sideRightToggle"]
    },
    item() {
      return this.$store.getters['storage/item']
    },
    sharedItem() {
      return this.$store.getters['share/item']
    },
    owner() {
      return this.$store.getters['user/fullname']
    },
    state(){
      return this.$store.getters['state/state'];
    },
    selectedWorkgroup(){
      return this.$store.getters['workgroup/workgroup'];
    },
    showProperties(){
      return this.$store.getters['storage/showProperties'];
    },
    showPropertiesShare(){
      return this.$store.getters['share/showProperties'];
    }
  },
  mounted() {
    const sideBarValue = this.$store.getters['storage/sideRightToggle'];
    // if(sideBarValue == true){
    //   this.$store.dispatch('storage/setSideRightToggle',{isShowing: sideBarValue, isPersistent: false});
    // }
    this.$store.dispatch('storage/setSideRightToggle',{isShowing: sideBarValue, isPersistent: true});
    this.$store.commit("share/SET_SHOW_PROPERTIES",false);
    this.$store.commit("storage/SET_ITEM",null);

    var showDetails = document.getElementById('show-details-info-sideRight')
  },
  methods: {
    detail(){
      this.$store.commit("workgroup/SET_TITLE",this.selectedWorkgroup.title);
      this.$store.commit("workgroup/SET_DESCRIPTION",this.selectedWorkgroup.description);
      this.$root.$emit('bv::show::modal', 'detailWorkgroup');
    },
    sidebarRight(){
      const shrink_btn = document.querySelector(".logo-box-sideRight")
      document.body.classList.toggle("logo-box-sideRight-shrink")
      shrink_btn.classList.add("hovered")

      setTimeout(
        () => {
          shrink_btn.classList.remove("hovered")
        }, 500
      )
    },
    sideRightToggle(){
      var showDetails = document.getElementById('show-details-info-sideRight')
      var showFiles = document.getElementById('show-files-in-chat')
      if(showDetails){
        if(this.checkedToggle == true){
          showFiles.classList.remove('show')
          showDetails.classList.remove('show')
          if(!showDetails.classList.contains('show') || !showFiles.classList.contains('show')){
            this.$root.$emit('bv::toggle::collapse', 'show-details-info-sideRight')
            this.$root.$emit('bv::toggle::collapse', 'show-files-in-chat')
          }
        } else {
            if(showDetails.classList.contains('show') || showFiles.classList.contains('show')){
              this.$root.$emit('bv::toggle::collapse', 'show-details-info-sideRight')
              this.$root.$emit('bv::toggle::collapse', 'show-files-in-chat')
            }
        }
      }
      this.$store.dispatch('storage/setSideRightToggle',{isShowing: !this.checkedToggle, isPersistent: false})
    },
    sideRightToggle2() {
      if(this.checkedToggle){
        this.$store.dispatch('storage/setSideRightToggle',{isShowing: false, isPersistent: false})
      }
    }
  }
}
</script>

<style>
.showOffToggle{
    position: absolute;
    right: 10px;
    height: 37px;
    cursor: pointer;
    padding: 8px 10px 12px 10px;
    border-radius: 8px;
}
body.logo-box-sideRight-shrink .showOffToggle img{
    transform: rotate(-180deg);
}

.sidebar-right{
    display: flex;
    flex-direction: row-reverse;
}
.sideRight{
    position: fixed;
    border-left: 1px solid #E9EAEA;
    background: white;
    width: 50px;
    right: 0;
}
.sideRight::before{
    content: "";
    position: absolute;
    /* width: 2rem; */
    height: 100%;
    right: 100%;
}
body.logo-box-sideRight-shrink .sideRight{
    width: 350px;
}
body.logo-box-sideRight-shrink .logo-box-sideRight img{
    transform: rotate(180deg);
}
.hide-sideRight{
    opacity: 0;
    transition: opacity 0.3s;
}
body.logo-box-sideRight-shrink .hide-sideRight{
    opacity: 1;
    pointer-events: none;
}
.sideRight .logo-sideRight{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E9EAEA;
    padding: 10px 15px 10px 15px;
    height: 45px;
}
.sideRight .logo-sideRight .logo-box-sideRight{
    height: 37px;
    cursor: pointer;
    padding: 8px 10px 12px 10px;
    position: absolute;
    left: -40px;
    margin-bottom: 0;
    border-radius: 10px;
    background-color: aquamarine;
    transform: translateY(0%) translateX(0%);
    box-shadow: 0 3px 10px -3px rgba(70, 46, 118, 0.3);
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
}
#logo-sideRight-hide{
    height: 16px;
    transition: 0.3s;
}
.sideRight .logo-sideRight .logo-box-sideRight:hover{
    background-color: aqua;
}
.sideRight:hover .logo-box-sideRight,
.logo-box-sideRight.hovered{
    transform: translateY(0%) translateX(0%);
    opacity: 1;
    pointer-events: all;
}
.sideRight .logo-sideRight .text-sideRight{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans';
    color: #262A2C;
}
.sideRight .info-sideRight{
    border-bottom: 1px solid #E9EAEA;
    padding: 10px 15px 10px 15px;
}
.sideRight .info-sideRight #show-details-info-sideRight{
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.sideRight .info-sideRight 
#show-shared-sideRight{
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.sideRight .participant-sideRight #show-participant-sidebarRight{
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  margin: 2px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.sideRight .info-sideRight button{
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}
.sideRight .info-sideRight .title-info-sideRight{
    display: flex;
    justify-content: left;
    align-items: center;
}
.sideRight .info-sideRight .title-info-sideRight .text-sideRight{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-weight: 500;
    height: 24px;
    color: #262A2C;
    font-family: 'Plus Jakarta Sans';
}
.sideRight .info-sideRight .img-info-sideRight{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sideRight .info-sideRight .img-info-sideRight img{
    height: 10rem;
    width: auto;
}
.sideRight .info-sideRight .shared-info-sideRight .text-shared-info-sideRight{
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.32px;
    color: #919394;
    font-family: 'Plus Jakarta Sans';
}
.sideRight .info-sideRight .shared-info-sideRight .text-manage-access-sideRight{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-weight: 400;
    font-family: 'Plus Jakarta Sans';
}
.sideRight .info-sideRight .detail-item-sideRight p{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.32px;
    font-weight: 400;
    margin: 0 0 8px 0;
    color: #919394;
    font-family: 'Plus Jakarta Sans';
}
.sideRight .info-sideRight .detail-item-sideRight p span{
    color: #262A2C;
}
.sideRight .participant-sideRight{
    border-bottom: 1px solid #E9EAEA;
    padding: 10px 15px 10px 15px;
}
.sideRight .participant-sideRight button{
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}
.sideRight .participant-sideRight .title-participant-sideRight{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sideRight .participant-sideRight .title-participant-sideRight .title-participant-sideRight-right{
    display: flex;
    justify-content: left;
    align-items: center;
}
.sideRight .participant-sideRight .title-participant-sideRight .title-participant-sideRight-right .text-sideRight-participant{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-weight: 500;
    color: #262A2C;
    font-family: 'Plus Jakarta Sans';
}
.sideRight .participant-sideRight .list-participant-sideRight .users-participant-sideRight{
    display: flex;
    justify-content: left;
    align-items: center;
}
.sideRight .participant-sideRight .list-participant-sideRight .users-participant-sideRight p{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 300;
    margin-bottom: 0;
}

@media (max-width: 767px) {
  body.logo-box-sideRight-shrink .sideRight{
    width: 100%;
    z-index: 99;
  }
}
</style>
