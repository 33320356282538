<template>
    <b-modal id="modal-add-participant-v3" no-close-on-backdrop hide-header hide-footer centered>
        <div class="container-add-participant">
            <span class="title-invite-wg-v3 mb-3">Invite participant</span>
            <!-- <pre>{{ workgroup }}</pre> -->
            <span class="sub-title-invite-wg-v3">Invite your teammates as a participant with full access to the workgroup.</span>
            <div id="emails-input-container" class="emails-input-container">
                <input
                    id="emails-input"
                    type="text-area"
                    class="emails-input"
                    placeholder="Enter participant’s email"
                    v-model="inputModel"
                    @focus="inputFocus()"
                    @focusout="outputFocus()"
                    v-on:keyup="showSuggest($event)"
                    v-on:keydown.enter.prevent="checkInput($event)"
                    v-on:keydown.tab.prevent="checkInput($event)">
                <ul class="suggest-box"></ul>
            </div>
            <b-dropdown v-if="workgroup.type != 'PRIVATE'" id="dropdown-modal-add-participant">
                <template #button-content>
                    <div class="d-flex align-items-center">
                        <span class="title-invite-wg-v3 mr-1">Invite as {{ selectedAccess.label }}</span>
                        <img src="@/assets/images/icon/chevDown.svg" alt="">
                    </div>
                </template>
                <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="changeAccess(ia)">
                    <div class="d-flex justify-content-start">
                        <img v-if="access.selected" src="@/assets/images/icon/Checklist.svg" alt="">
                        <span v-else class="mx-2"></span>
                        <span class="title-invite-wg-v3 mx-3">{{ access.label }}</span>
                    </div>
                </b-dropdown-item>
            </b-dropdown>
            <!-- <input
                type="text"
                class="input-msg-add-participant mb-4"
                placeholder="Message (Optional)"> -->
            <div v-if="workgroup.type == 'PRIVATE'" class="my-4"></div>
            <div class="d-flex justify-content-end align-items-center">
                <button class="skip-add-btn-v3 main-color-api" @click="skipBtn()">Skip</button>
                <button
                  class="ml-3 inv-btn-participant-v3 main-btn-api"
                  @click="sendBtn()"
                  :disabled="this.tagsEmail.length <= 0">Invite</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
  data () {
    return {
      tagsEmail: [],
      inputModel: '',
      accessTypes : [
        {
          id : "View",
          label : "Viewer",
          selected : true
        },
        {
          id : "Write",
          label : "Editor",
          selected : false
        }
      ],
    }
  },
  computed: {
    workgroup() {
      return this.$store.getters['workgroup/workgroup']
    },
    title: {
      // getter
      get: function () {
        return this.$store.getters['workgroup/title'];
      },
      // setter
      set: function (newValue) {
        this.$store.commit("workgroup/SET_TITLE",newValue);
      }
    },
    description: {
      // getter
      get: function () {
        return this.$store.getters['workgroup/description'];
      },
      // setter
      set: function (newValue) {
        this.$store.commit("workgroup/SET_DESCRIPTION",newValue);
      }
    },
    selectedAccess(){
      return this.accessTypes.filter(v=>v.selected)[0]
    }
  },
  methods: {
    inputFocus() {
      var containerInput = document.getElementById('emails-input-container')
      containerInput.style.border = '2px solid #00AAFF'
    },
    outputFocus() {
      var containerInput = document.getElementById('emails-input-container')
      containerInput.style.border = '1px solid #E9EAEA'
    },
    showSuggest (e) {
      var value = this.inputModel
      this.removeElements()
      if (this.isValidEmail(value) && value !== '') {
        const suggestEmail = document.createElement('li')
        suggestEmail.classList.add('suggest-email')
        suggestEmail.style.cursor = 'pointer'
        suggestEmail.addEventListener('click', this.displayEmail, false)
        suggestEmail.innerHTML = value
        document.querySelector('.suggest-box').appendChild(suggestEmail)
      }
    },
    displayEmail (e) {
      this.tagsEmail.push(e.target.innerText)
      this.addTags()
      this.removeElements()
      this.inputModel = ''
    },
    removeElements () {
      var items = document.querySelectorAll('.suggest-email')
      items.forEach((item) => {
        item.remove()
      })
    },
    checkInput (e) {
      this.tagsEmail.push(this.inputModel)
      this.addTags()
      this.inputModel = ''
    },
    addTags () {
      var inputContainer = document.getElementById('emails-input-container')
      this.reset()
      this.tagsEmail.slice().reverse().forEach((tag) => {
        var input = this.isValidEmail(tag) ? this.createTag(tag) : this.errorTag(tag)
        inputContainer.prepend(input)
      })
    },
    reset () {
      document.querySelectorAll('.tag').forEach((tag) => {
        tag.parentElement.removeChild(tag)
      })
    },
    createTag (label) {
      const div = document.createElement('div')
      div.setAttribute('class', 'tag')
      const span = document.createElement('span')
      span.innerHTML = label
      const closeBtn = document.createElement('i')
      closeBtn.setAttribute('class', 'icon-close-v3')
      closeBtn.setAttribute('data-item', label)
      closeBtn.innerHTML = '&times;'
      div.appendChild(span)
      div.appendChild(closeBtn)

      var self = this
      closeBtn.addEventListener('click', function (e) {
        if (e.target.tagName === 'I') {
          const value = e.target.getAttribute('data-item')
          const index = self.tagsEmail.indexOf(value)
          self.tagsEmail = [...self.tagsEmail.slice(0, index), ...self.tagsEmail.slice(index + 1)]
          self.addTags()
        }
      })
      return div
    },
    errorTag (label) {
      const div = document.createElement('div')
      div.setAttribute('class', 'tag error-tag')
      const span = document.createElement('span')
      span.innerHTML = label
      const closeBtn = document.createElement('i')
      closeBtn.setAttribute('class', 'icon-close-error-v3')
      closeBtn.setAttribute('data-item', label)
      closeBtn.innerHTML = '&times;'
      div.appendChild(span)
      div.appendChild(closeBtn)

      var self = this
      closeBtn.addEventListener('click', function (e) {
        if (e.target.tagName === 'I') {
          const value = e.target.getAttribute('data-item')
          const index = self.tagsEmail.indexOf(value)
          self.tagsEmail = [...self.tagsEmail.slice(0, index), ...self.tagsEmail.slice(index + 1)]
          self.addTags()
        }
      })
      return div
    },
    isValidEmail (email) {
      if (email === '') return false
      const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regexEmail.test(email)
    },
    changeAccess(idx){
      this.$ga.event(process.env.VUE_APP_GA_CREATE_WORKGROUP_CHANGE_PRIV, 'Create WG - Change User Privilege', 'wg-action', 1)
      this.accessTypes = this.accessTypes.map((v,i)=>{
        return {
          label: v.label,
          selected: i == idx,
          id: v.id
        }
      })
    },
    sendBtn () {
      var sendVerify = true
      if (this.tagsEmail.length > 0) {
        this.tagsEmail.forEach((tag) => {
          if (!this.isValidEmail(tag)) {
            sendVerify = false
          }
        })
      }
      if (sendVerify) {
        var params = {
          title: this.workgroup.title,
          description: this.workgroup.description,
          participant: this.tagsEmail.map((v) => {
            return {
              email: v,
              access_type : this.workgroup.type != 'PRIVATE' ? this.selectedAccess.id : 'Private'
            }
          })
        }
        this.$store.dispatch("workgroup/create",params)
        .then((_) => {
          this.$toasted.show('Invitation sent successfully.',{ 
            theme: "toasted-primary", 
            position: "bottom-center", 
            duration : 2000
          })
          this.$root.$emit('bv::hide::modal', 'modal-add-participant-v3')
          this.title = null
          this.description = ''
          this.tagsEmail = []
        })
        .catch(err => {
          this.$toasted.show('Invitation failed to send. Make sure your internet is connected, then try again.',{ 
            theme: "toasted-primary", 
            position: "bottom-center", 
            duration : 2000
          })
          this.$root.$emit('bv::hide::modal', 'modal-add-participant-v3')
          this.title = null
          this.description = ''
          this.tagsEmail = []
        })
      } else {
        this.$toasted.show('Invalid email input.',{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000
        })
      }
    },
    skipBtn () {
      this.$ga.event(process.env.VUE_APP_GA_CREATE_WORKGROUP_BTN_SKIP, 'Create WG - Skip Add Participants', 'wg-action', 1)
      this.$root.$emit('bv::hide::modal', 'modal-add-participant-v3')
    }
  }
}
</script>

<style>
.inv-btn-participant-v3{
    width: auto;
    height: 40px;
    padding: 8px 16px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -1px;
    border: none;
    border-radius: 4px;
}
.inv-btn-participant-v3:disabled{
    background: #E9EAEA;
    color: #919394;
}
.skip-add-btn-v3{
    background: #FFFFFF;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -1px;
    border: none;
}
.skip-add-btn-v3:focus{
    background: none;
}
#dropdown-modal-add-participant{
    width: fit-content;
}
#dropdown-modal-add-participant button{
    background: #FFFFFF !important;
    color: #262A2C !important;
    border: none;
    margin: 20px 0;
    padding: 0;
}
.container-add-participant{
    display: flex;
    flex-direction: column;
}
.title-invite-wg-v3{
    color: #262A2C;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1px;
}
.sub-title-invite-wg-v3{
    color: #919394;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: -1px;
    margin-bottom: 32px;
}
.emails-input-container{
  border: 1px solid #E9EAEA;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  width: 384px;
  height: 132px;
  background: #FFFFFF;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
}
.emails-input-container:focus{
    border: 2px solid #00AAFF;
}
.emails-input{
  width: 100%;
  height: 32px;
  border: none;
  color: #262A2C;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: -1px;
}
.emails-input:focus{
  border: #FFFFFF !important;
  outline: none;
}
.emails-input::placeholder{
    color: #919394;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: -1px;
}
.emails-input-container .tag{
  padding: 4px 8px;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #E9F5FB;
  margin: 0 8px 8px 0;
  cursor: default;
  font-size: 16px;
  line-height: 24px;
  color: #262A2C;
  font-weight: 400;
  letter-spacing: -1px;
}
.icon-close-v3{
  background: #00AAFF;
  height: 16px;
  width: 16px;
  color: #FFFFFF;
  width: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  border-radius: 10px;
  padding-right: 6px;
  padding-left: 3px;
  padding-top: 2px;
}
.icon-close-error-v3{
  background: #D42020;
  height: 16px;
  width: 16px;
  color: #FFFFFF;
  width: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  border-radius: 10px;
  padding-right: 6px;
  padding-left: 3px;
  padding-top: 2px;
}
.emails-input-container .error-tag{
  background: #FBE9E9 !important;
}
.input-msg-add-participant{
    width: 384px;
    padding: 16px;
    border: 1px solid #E9EAEA;
    border-radius: 4px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: -1px;
    color: #262A2C;
}
.input-msg-add-participant::placeholder{
    color: #919394;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: -1px;
}
.input-msg-add-participant:focus{
    outline: 2px solid #00AAFF;
}
.suggest-box{
    padding: 0px;
}
.suggest-email{
  background: #E9EAEA;
  list-style: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #262A2C;
  letter-spacing: -1px;
}
</style>