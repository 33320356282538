<script>
export default {
    data() {
        return {
        };
    },
    computed: {
        breadcumb() {
            return this.$store.getters[this.$store.getters['state/state']+'/breadcumbs']
        },
        item() {
            let items = this.$store.getters['storage/selected']
            return items[items.length-1]
        },
        id() {
            return this.$store.getters['user/id']
        },
        owner() {
            return this.$store.getters['user/fullname']
        },
        firstName() {
            return this.$store.getters['user/firstName']
        },
        lastName() {
            return this.$store.getters['user/lastName']
        },
        state(){
            return this.$store.getters['state/state'];
        },
        selectedWorkgroup(){
            return this.$store.getters['workgroup/workgroup']
        },
        ownerFile(){
            if(this.item.added_by == this.id){
                // return 'Me'
                return this.firstName + ' ' + this.lastName
            } else {
                if(this.item.added_by == this.selectedWorkgroup.owner_id){
                    return this.upperCase(this.selectedWorkgroup.name)
                } else {
                    var index = this.selectedWorkgroup.participant.findIndex((v)=>{
                        return v._id == this.item.added_by
                    })
                    var firstName = this.selectedWorkgroup.participant[index].firts_name
                    var lastName = this.selectedWorkgroup.participant[index].lastname
                    return `${firstName} ${lastName}`
                }
            }
        },
        isOwner(){
            var id = this.$store.getters['user/id'];
            var group = this.$store.getters['workgroup/workgroup'];
            if(group == null) {
                return true
            } else {
                return id == group.owner_id;
            }
        }
    },
    methods:{
      close(){
            this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
            // var header = document.getElementById("page-topbar");
            // header.style.right = "0"; 
            // var mainpage = document.getElementById("main-page");
            // mainpage.style.marginRight = "10px"; 
      },
      shareopen(item){
        this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
        this.$store.commit("share/SET_SHOW_PROPERTIES",false);
        // var header = document.getElementById("page-topbar");
        // header.style.right = "0"; 
        // var mainpage = document.getElementById("main-page");
        // mainpage.style.marginRight = "10px"; 
        this.$store.commit("storage/SET_ITEM",item);
        this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
        this.$root.$emit('bv::show::modal', 'modalShare');
      },
      upperCase(str){
        let strIndex = str.split('')
        let arr = [0]
        for(let i = 0; i < arr.length; i++){
          if(strIndex[arr[i]]){
              strIndex[arr[i]] = strIndex[arr[i]].toUpperCase()
          }
        }
        return strIndex.join('')
      },
    },
    mounted() {
        this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
        this.$store.commit("share/SET_SHOW_PROPERTIES",false);
    }
};
</script>
<template>
    <div>
        <div v-if="item == null">
            <div class="empty-item-sideRight mt-2">
                <div class="empty-details-information mt-3">
                    <img src="@/assets/images/empty-information.svg" alt="">
                    <span class="mt-2">Select a folder or file to see the</span>
                    <span class="">information.</span>
                </div>
            </div>
        </div>
        <div v-if="item != null">
            <div class="img-info-sideRight mt-2">
                <!-- <pre>{{item}}</pre> -->
                <img v-if="item.encrypt == 2 || item.encrypt == 7" src="@/assets/images/icon/lock-encrypt.svg" alt=""/>
                <img v-else :src="require(`@/assets/images/icon/${item.icon}`)" alt=""/>
            </div>
            <!-- <pre>{{item}}</pre> -->
            <div class="detail-item-sideRight mt-5 row">
                <div class="key-detail-item-sideRight col-5">
                    <p class="mt-4">Name</p>
                    <p class="mt-4">Type</p>
                    <p class="mt-4">Location</p>
                    <p class="mt-4">Owner</p>
                    <p class="mt-4">Modified</p>
                    <p class="mt-4">Created</p>
                </div>
                <div class="target-detail-item-sideRight col-7">
                    <p class="file-name-details mt-4"><span>{{item.file_name}}</span></p>
                    <p class="mt-4"><span>{{item.isFolder ? 'Folder' : item.extension}}</span></p>
                    <p class="mt-4">
                        <span v-if="breadcumb.length < 1">{{state == 'storage' ? 'All Files' : 'Group Files'}}</span>
                        <span v-if="breadcumb.length > 0">{{breadcumb[breadcumb.length-1].file_name.substring(0,15)}}{{breadcumb[breadcumb.length-1].file_name.length > 15 ? '...':''}}</span>
                    </p>
                    <p v-if="state == 'storage'" class="file-name-details mt-4"><span>{{upperCase(firstName)}} {{upperCase(lastName)}}</span></p>
                    <p v-else class="file-name-details mt-4"><span>{{ownerFile}}</span></p>
                    <p class="mt-4"><span>{{item.datemodified}}</span></p>
                    <p class="mt-4"><span>{{item.datecreated}}</span></p>
                </div>
            </div>
        </div>
    </div>
    <!-- <div>
        <div v-if="item == null">
            <p> no file/folder </p>
        </div>
    <div class="col-3 p-0 border-left properties" v-if="item != null">
        <div class="d-flex p-4 justify-content-between align-items-center mt-4">
            <div   class="d-flex align-items-center">
                <img :src="require(`@/assets/images/icon/${item.icon}`)" height="20px" alt />
                <span class="mx-2 wraptext default-text-style">{{item.file_name}}</span>
            </div>
            <span class="cursor-pointer px-2" @click="close()">
                <img src="@/assets/images/icon/Close.png" alt width="100%" />
            </span>
        </div>
        <b-tabs  class="tabs-properties" card >
            <b-tab title="Details" class="px-4">
                <div class="p-2 m-2 d-flex justify-content-center align-items-center">
                    <img :src="require(`@/assets/images/icon/lg/${item.icon}`)" class="icon-lg" alt />
                </div>
                <div class="mt-4">
                    <p class="default-text-style bold-normal">Has access</p>
                    <div class="d-flex mb-2">
                        <img src="@/assets/images/icon/Icon.png"  v-show="!item.isShare" class="mx-1" alt />
                        <div class="avatar-xs mx-1" v-show="item.isShare">
                            <span :id="owner" class="avatar-title rounded-circle">{{owner.charAt(0).toUpperCase()}}</span>
                            <b-tooltip :target="owner" triggers="hover">
                                {{owner}} <b>is owner</b>
                            </b-tooltip>
                        </div>
                        <div class="d-flex" v-for="(shared,index) in item.users_share" :key="index">
                            <div class="avatar-xs mx-1" v-for="(user,i) in shared.users_id" :key="i">
                                <span :id="user.email" class="avatar-title rounded-circle bg-pink">{{user.email.charAt(0).toUpperCase()}}</span>
                                <b-tooltip :target="user.email" triggers="hover">
                                    {{user.email}} can <b>{{user.access_type}}</b>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                    <p class="default-text-style " v-if="!item.isShare"><span class="text-muted font-size-12 letter05">This item is not shared</span></p>
                    <p class="default-text-style  " v-else><span class="text-muted font-size-12 letter05">This item is shared</span></p>
                    <a href="javascript:void();" v-if="!item.isFolder && state != 'workgroup'" @click="shareopen(item)" class="default-text-style bold-normal"><span class="text-blue">Manage Access</span></a>
                </div>
                <div class="mt-2 pt-4" id="detail">
                    <p class="default-text-style">Details</p>
                    <table class="table default-text-style" :borderless="true" :bordered="false" small width="100%">
                        <tr>
                            <td class="text-muted">Type</td>
                            <td>{{item.isFolder ? 'Folder' : item.extension}}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Size</td>
                            <td>{{item}}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">State</td>
                            <td>Editor</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Owner</td>
                            <td>me</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Modified</td>
                            <td>{{item.datemodified}}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Opened</td>
                            <td>{{item.datemodified}}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Made</td>
                            <td>{{item.datecreated}}</td>
                        </tr>
                    </table>
                </div>
            </b-tab>
        </b-tabs>
    </div>
    </div> -->
</template>