<script>
export default {
    data() {
        return {
        };
    },
    computed: {
        item() {
            return this.$store.getters['storage/item']
        },
        owner() {
            return this.$store.getters['user/fullname']
        },
        state(){
            return this.$store.getters['state/state'];
        }
    },
    methods:{
      shareopen(item){
        this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
        this.$store.commit("share/SET_SHOW_PROPERTIES",false);
        this.$store.commit("storage/SET_ITEM",item);
        this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
        this.$bvModal.hide('modal-share-file-v3')
        this.$router.push({name: 'FileShared', query: {id: item._id, d: item.directory }})
      },
    },
    mounted() {
    }
};
</script>

<template>
    <div>
        <div v-if="item == null" class="empty-item-sideRight mt-2">
            <p>Select a folder or file to see information</p>
        </div>
        <div v-else class="shared-info-sideRight">
            <!-- {{item}} -->
            <div v-if="!item.isShare">
                <img src="@/assets/images/icon/lock-icon.png" alt="" id="img-not-shared-info-sideRight" class="mt-3">
                <div class="text-shared-info-sideRight mt-4 mb-2">This item is not shared</div>
            </div>
            <div v-else>
                <div class="d-flex">
                    <div class="mt-3" v-for="(user,i) in item.users_share[0].users_id" :key="i">
                    <!-- <b-avatar-group size="32px"> -->
                            <b-avatar class="mr-2 mt-2 main-btn-api" style="cursor: pointer" size="32px" v-b-tooltip.hover :title="user.email">{{user.email.charAt(0).toUpperCase()}}</b-avatar>
                    <!-- </b-avatar-group> -->
                    </div>
                </div>
                <div class="mt-4 mb-2">
                    <div class="text-shared-info-sideRight mt-2">This item is shared</div>
                </div>
            </div>
            <a class="text-manage-access-sideRight main-color-api cursor-pointer" v-if="!item.isFolder && state != 'workgroup'" @click="shareopen(item)">Manage access</a>
        </div>
    </div>
</template>
