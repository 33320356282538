<template>
    <b-modal id="modalAlertMaxWorkgroup" no-close-on-backdrop hide-header hide-footer centered>
        <div class="d-flex flex-column">
            <span class="enterprise-text-desc">Add to favorite failed</span>
            <span class="my-3 enterprise-member-list-text">Sorry, you can only add 5 workgroups to favorite. Please unfavorite the workgroup to add a new one.</span>
            <div class="d-flex justify-content-end align-items-center mt-3 close-and-submit-enterprise">
                <button
                    @click="close()"
                    class="ml-3"
                >
                    Got it
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    methods: {
        close() {
            this.$bvModal.hide('modalAlertMaxWorkgroup')
        }
    }
}
</script>

<style>

</style>