<template>
    <b-modal ref="modal-checkout" id="modal-checkout-v3" no-close-on-backdrop hide-header hide-footer>
        <img src="@/assets/images/icon/Close.svg" alt="" class="cursor-pointer close-modal-checkout-v3" @click="close()">
        <span class="mb-3 checkout-modal-title justify-content-center align-items-center d-flex">Checkout</span>
        <div class="box-form-checkout-v3 pt-3">
            <div v-if="packageById.is_enterprise == true" class="left-modal-checkout-v3">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <span class="sub-title-checkout-v3">{{ packageById.name_package }}</span>
                    <span class="sub-title-checkout-v3 text-blue cursor-pointer" @click="goToPackages()">Change Plan</span>
                </div>
                <p class="mb-3 checkout-modal-title">{{ prices ? packageById.price_month : packageById.price_year }}<span> /{{ prices ? 'month' : 'year' }}</span></p>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Free for up to {{packageById.free_user}} seats, then ${{packageById.price_user}}/seat/month</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Unlimited workgroups</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Unlimited workgroup participants</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Unlimited file size per upload</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Adimn dashboard</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Custom domain</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Customized logo and colors</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Encryption</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Real-time collaboration</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>File recovery</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Desktop and mobile access</span>
                </div>
                <div class="d-flex justify-content-between align-items-center resume-price-v3 margin-top-40px mb-3">
                    <span class="resume-checkout-title-v3">{{ packageById.name_package }}</span>
                    <span class="resume-checkout-price-v3">${{ prices ? packageById.price_month : packageById.price_year }}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="resume-checkout-title-v3">Total</span>
                    <span class="resume-checkout-price-v3">${{ prices ? packageById.price_month : packageById.price_year }}</span>
                </div>
            </div>
            <div v-else class="left-modal-checkout-v3">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <span class="sub-title-checkout-v3">{{ packageById.name_package }}</span>
                    <span class="sub-title-checkout-v3 text-blue cursor-pointer" @click="goToPackages()">Change Plan</span>
                </div>
                <p class="mb-3 checkout-modal-title">{{ prices ? packageById.price_month : packageById.price_year }}<span> /{{ prices ? 'month' : 'year' }}</span></p>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>{{ packageById.workgroup.max_workgroup }} workgroups</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>{{ packageById.workgroup.max_participants }} workgroup participants</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>{{ packageById.max_uploaded }} max. file size per upload</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Encryption</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Real-time collaboration</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>File recovery</span>
                </div>
                <div class="details-checkout-v3">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Desktop and mobile access</span>
                </div>
                <div class="d-flex justify-content-between align-items-center resume-price-v3 margin-top-40px mb-3">
                    <span class="resume-checkout-title-v3">{{ packageById.name_package }}</span>
                    <span class="resume-checkout-price-v3">${{ prices ? packageById.price_month : packageById.price_year }}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="resume-checkout-title-v3">Total</span>
                    <span class="resume-checkout-price-v3">${{ prices ? packageById.price_month : packageById.price_year }}</span>
                </div>
            </div>
            <div class="right-modal-checkout-v3">
                <span class="sub-title-checkout-v3">Billing cycle</span>
                <div v-if="packageById.is_enterprise == true" class="d-flex billing-price-v3">
                    <div class="checkout-price-radio-v3 d-flex justify-content-start align-items-start">
                        <input type="radio" name="radio" class="cursor-pointer radio-modal-checkout mr-3 mt-1" id="modal-month-price" :value="true" v-model="prices"/>
                        <label class="cursor-pointer radio-modal-checkout-label" for="modal-month-price">
                            <p>Monthly</p>
                            <p>${{ packageById.price_month }}/month</p>
                        </label>
                    </div>
                </div>
                <div v-else class="d-flex billing-price-v3">
                    <div class="checkout-price-radio-v3 d-flex justify-content-start align-items-start">
                        <input type="radio" name="radio" class="cursor-pointer radio-modal-checkout mr-3 mt-1" id="modal-month-price" :value="true" v-model="prices"/>
                        <label class="cursor-pointer radio-modal-checkout-label" for="modal-month-price">
                            <p>Monthly</p>
                            <p>${{ packageById.price_month }}/month</p>
                        </label>
                    </div>
                    <div class="checkout-price-radio-v3 d-flex justify-content-start align-items-start">
                        <input type="radio" name="radio" class="cursor-pointer radio-modal-checkout mr-3 mt-1" id="modal-year-price" :value="false" v-model="prices"/>
                        <label class="cursor-pointer radio-modal-checkout-label" for="modal-year-price">
                            <p>Yearly</p>
                            <p>${{ packageById.price_year }}/year</p>
                            <p>(${{ divided12(packageById.price_year) }}/month)</p>
                        </label>
                    </div>
                </div>
                <div v-if="cardMethod != null" class="d-flex flex-column payment-method-card-modal-checkout">
                    <span class="sub-title-checkout-v3 mb-3">Payment method</span>
                    <input type="text" :value="cardNumber" disabled class="form-control">
                </div>
                <b-form-checkbox
                    id="checkbox-payment-modal"
                    name="checkbox-payment-modal"
                    value="accepted"
                    unchecked-value="not_accepted"
                    v-model="termConditionCheckout"
                >
                    <p class="terms-polic-modal-checkout">I have read and agree to the Terms & Policy</p>
                </b-form-checkbox>
                <button
                    :disabled="termConditionCheckout == 'not_accepted' ? termConditionCheckout : !termConditionCheckout"
                    :class="termConditionCheckout == false || termConditionCheckout == 'not_accepted' ? 'btn-checkout-pay-modal-disabled-v3' : 'btn-checkout-pay-modal-v3'"
                    @click="payPackage()"
                >Continue</button>
                <!-- <p>{{ this.termConditionCheckout }}</p> -->
                <!-- <p>{{ packageById }}</p> -->
                <!-- <p>{{ cardMethod }}</p> -->
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            termConditionCheckout: false
        }
    },
    computed: {
        prices: {
            get: function () {
                return this.$store.getters['packages/selectedPrice']
            },
                set: function (newValue) {
                this.$store.commit('packages/SET_SELECTED_PRICE', newValue);
            }
        },
        packageById: {
            cache: false,
            get() {
            let dataId = this.$store.getters["packages/packageById"]
            if(dataId.basic !== undefined) {
                dataId.basic.max_upload = dataId.basic.max_upload.toString()
                if (dataId.basic.max_upload !== 'unlimited') {
                    dataId.max_uploaded = this.niceBytes(dataId.basic.max_upload)
                } else {
                    dataId.max_uploaded = this.upperCase(dataId.basic.max_upload)
                }
            }
            return dataId
            }
        },
        cardMethod() {
            return this.$store.getters["user/card_payment"]
        },
        cardNumber() {
            var data = this.$store.getters["user/card_payment"]
            return ` •••• •••• •••• ${data.last4}`
        },
        emailUser() {
            return this.$store.getters['user/email']
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('modal-checkout-v3')
        },
        goToPackages() {
            this.$bvModal.show('modalPackages')
            this.$bvModal.hide('modal-checkout-v3')
        },
        payPackage() {
            const payload = {
                email: this.emailUser,
                price_id: this.prices ? this.packageById.stripe_price_month : this.packageById.stripe_price_year
            }
            const finalPayload = {
                payload,
                is_enterprise: this.packageById.is_enterprise == true ? true : false
            }
            this.$store.dispatch('packages/getCheckout', finalPayload)
        },
        niceBytes(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return 'n/a';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            if (i == 0) return bytes + ' ' + sizes[i];
            return (bytes / Math.pow(1024, i)).toFixed() + ' ' + sizes[i];
        },
        divided12(value) {
            var price = value / 12
            return price.toFixed()
        },
        upperCase(str) {
            let strIndex = str.split('')
            let arr = [0]
            for(let i = 0; i < arr.length; i++){
                if(strIndex[arr[i]]){
                strIndex[arr[i]] = strIndex[arr[i]].toUpperCase()
                }
            }
            return strIndex.join('')
        },
    }
}
</script>

<style lang="scss">
#modal-checkout-v3{
    padding: 0;
    .modal-dialog{
        max-width: 912px !important;
        .modal-content{
            background-color: #F6F6FC;
            padding: 32px !important;
            .modal-body{
                padding: 0 !important;
            }
        }
    }
}
.close-modal-checkout-v3{
    position: absolute;
    top: 0;
    right: 0;
}
.box-form-checkout-v3{
    width: 100%;
    display: flex;
}
.checkout-modal-title{
    font-size: 24px;
    height: 36px;
    font-weight: 500;
    color: #262A2C;
    letter-spacing: -0.04em;
}
.checkout-modal-title span{
    font-size: 16px;
    height: 20px;
    font-weight: 300;
    color: #262A2C;
    letter-spacing: -1px;
    opacity: 0.5;
}
.left-modal-checkout-v3{
    width: 50%;
    padding-right: 32px;
    margin: 0;
}
.right-modal-checkout-v3{
    width: 50%;
    padding-left: 32px;
    margin: 0px;
}
.sub-title-checkout-v3{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #262A2C;
    letter-spacing: -1px;
}
.details-checkout-v3{
    padding-top: 16px;
}
.details-checkout-v3 img{
    margin-right: 16px;
    margin-top: -5px;
}
.details-checkout-v3 span{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #262A2C;
    font-weight: 400;
}
.resume-price-v3{
    border-bottom: 1px solid #E9EAEA;
    padding-bottom: 20px;
}
.resume-checkout-title-v3{
    font-size: 16px;
    height: 20px;
    font-weight: 300;
    color: #262A2C;
    letter-spacing: -1px;
    opacity: 0.7;
}
.resume-checkout-price-v3{
    font-size: 16px;
    height: 24px;
    font-weight: 400;
    color: #262A2C;
    letter-spacing: -1px;

}
.margin-top-40px{
    margin-top: 40px;
}
.billing-price-v3{
    padding-top: 16px;
    margin-bottom: 40px;
}
.checkout-price-radio-v3{
    width: 50%;
}
.radio-modal-checkout-label p{
    font-size: 16px;
    color: #262A2C;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: -1px;
    margin-bottom: 0;
}
.terms-polic-modal-checkout{
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #262A2C;
    letter-spacing: -1px;
    margin: 0;
}
.btn-checkout-pay-modal-v3{
    width: 100%;
    margin-top: 40px;
    height: 40px;
    border: none;
    background: #00AAFF;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
}
.btn-checkout-pay-modal-disabled-v3{
    width: 100%;
    margin-top: 40px;
    height: 40px;
    border: none;
    background: #E9EAEA;
    color: #919394;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
}
.form-card-checkout{
    height: 52px;
}
.payment-method-card-modal-checkout{
    margin-bottom: 40px;
}
.payment-method-card-modal-checkout input:disabled{
    padding: 16px;
    height: 52px;
    background-color: #E9EAEA !important;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 300;
    border: none !important;
}
@media (max-width: 767px) {
    #modal-checkout-v3{
        padding: 0;
        .modal{
            width: 100vw;
        }
        .modal-dialog{
            max-width: 432px !important;
            .modal-content{
                background-color: #F6F6FC;
                padding: 24px 0 0 0 !important;
            }
        }
    }
    .box-form-checkout-v3{
        width: fit-content;
        flex-wrap: wrap;
    }
    .left-modal-checkout-v3{
        width: 100%;
        padding: 24px !important;
        margin: 0;
    }
    .right-modal-checkout-v3{
        width: 100%;
        padding: 24px !important;
        margin: 0;
    }
    .close-modal-checkout-v3{
        position: absolute;
        top: -2px;
        right: 18px;
    }
}
</style>