<script>
export default {
    computed:{
        item(){
            return this.$store.getters['workgroup/workgroup']
        },
        email(){
            return this.$store.getters['user/email']
        }
    },
    data() {
        return {
        }
    },
    methods:{
      cancel(){
          this.$root.$emit('bv::hide::modal', 'modalLeaveWorkgroup');
      },
      leaveWorkgroup(id){
        this.$ga.event('leave_wg', 'actionx', 'labelx', 1)
        var payload = {
            workgroup_id: id,
            email: this.email
        }
        this.$store.dispatch("workgroup/leaveWg",payload).then(()=>{            
            this.$root.$emit('bv::hide::modal', 'modalLeaveWorkgroup');
            this.$store.commit("workgroup/SET_WORKGROUP",null);
            this.$store.commit("state/SET_STATE","storage");
            this.$store.commit("storage/SET_KEYWORD","");
            this.$store.commit("storage/SET_PARENT_ID","");
            this.$store.commit("storage/DELETE_BREADCUMB",[]);
            this.$store.dispatch("storage/listing")
            var jarak = document.getElementById("jarak");
            jarak.style.display = "none";
            this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
            // var header = document.getElementById("page-topbar");
            // header.style.right = "0"; 
            // var mainpage = document.getElementById("main-page");
            // mainpage.style.marginRight = "10px";
            this.$router.push({name: 'home'});
        });
      }
    }
};
</script>
<template>
    <b-modal id="modalLeaveWorkgroup" no-fade  content-class="shadow" :hide-header="true"  :hide-footer="true"  centered>
        <div v-if="item != null">
            <h2 class="title-modal-delete-wg">Leave this workgroup?</h2>
            <span class="modal-delete-alert-wg">You’ll no longer have access to files in this workgroup.</span>
            <div class="d-flex justify-content-end align-items-center mt-4">
                <button class="mr-3 submit-delete-modal-wg main-color-api" @click="cancel()">Cancel</button>
                <button class="cancel-delete-modal-wg main-btn-negative" @click="leaveWorkgroup(item._id)">Leave</button>
            </div>
            <!-- <ul class="modal-list-delete-wg">
                <li class="modal-delete-alert-wg mb-3">Your files will be gone forever, and you’ll no longer have access to anything that’s been shared with you.</li>
                <li class="modal-delete-alert-wg mb-4">Leaving your account is not reversible. Once leave, we cannot retrieve it.</li>
            </ul> -->
            <!-- <div class="d-flex justify-content-end align-items-center mt-4">
                <button class="mr-3 submit-delete-modal-wg" @click="leaveWorkgroup(item._id)">Yes leave</button>
                <button class="cancel-delete-modal-wg" @click="cancel()">Cancel</button>
            </div> -->
        </div>
    </b-modal>
</template>

<style>
.title-modal-delete-wg{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #262A2C;
    letter-spacing: -1px;
    margin-bottom: 16px;
}
.modal-delete-alert-wg{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #262A2C;
    letter-spacing: -1px;
}
.modal-list-delete-wg{
    padding: 0 0 0 18px;
}
.cancel-delete-modal-wg{
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
}
.submit-delete-modal-wg{
    padding: 8px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: none;
    border: none;
    border-radius: 4px;
}
.submit-delete-modal-wg:focus{
    background: none;
}
</style>