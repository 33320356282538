<script>
import { maxLength, required } from 'vuelidate/lib/validators'

export default {
    computed:{
        item(){
            return this.$store.getters[this.$store.getters["state/state"]+"/item"];
        },
        loading(){
            return this.$store.getters[this.$store.getters["state/state"]+"/loading"];
        },
        parent_id(){
            return this.$store.getters[this.$store.getters["state/state"]+"/parent_id"];
        },
        state(){
            return this.$store.getters["state/state"];
        }
    },
    data() {
        return {
            folderName: null,
            invalidFolderName : "",
            validFolderName : "",
            stateFolderName : true,
        };
    },
    validations: {
        folderName: {
            maxLength: maxLength(255),
            required
        },
    },
    watch: {
        folderName() {
            if(this.folderName == '' || this.folderName == null){
                this.invalidFolderName = ''
                this.stateFolderName = true
            }
        }
    },
    methods:{
        validationStatus(validation) {
            return typeof validation != undefined ? validation.$error: false
        },
        NameValidation(e){

            if(!e.key.match(/^[a-zA-Z0-9 _-]*$/))
            {
                e.preventDefault();
            }
           
        },
        close(){
            this.$root.$emit('bv::hide::modal', 'modalContainer')
        },
        submitEvent() {
            if(this.state == 'storage'){
                this.$ga.event(process.env.VUE_APP_GA_CREATE_FOLDER, 'Click Create Folder', 'personal-acces-file', 1)
            } else {
                this.$ga.event(process.env.VUE_APP_GA_WG_GF_CREATE_FOLDER, 'WG - Create Folder', 'wg-access-file', 1)
            }
            this.stateFolderName = true;
            return (
                this.$store.dispatch(this.$store.getters["state/state"]+"/newFolder",{
                    folder_name: this.folderName,
                    parent_id : this.parent_id
                })
                // eslint-disable-next-line no-unused-vars
                .then(token => {
                    this.folderName = null;
                    this.validFolderName = token;
                    this.stateFolderName = false;
                    this.$store.dispatch(this.state+"/listing","noclear")
                    this.$root.$emit('bv::hide::modal', 'modalContainer')
                    this.$toasted.show('Folder created successfully.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                })
                .catch(error => {
                    this.invalidFolderName = error ? error : "";
                    this.stateFolderName = false;
                })
            );
        }
    }
};
</script>
<template>
    <div>
        <h1 class="h1-title default-text-style bold-normal">Create a new folder</h1>
            <b-form action="" @submit.prevent="submitEvent">
                <div class="form-input">
                <!-- <b-form-group > -->
                    <b-form-input
                        v-model.trim="$v.folderName.$model"
                        v-on:keydown="NameValidation($event)"
                        type="text"
                        class="form-control input-box-v3"
                        :class="{'is-invalid': validationStatus($v.folderName) || (!stateFolderName)}"
                        placeholder="Folder name"></b-form-input>
                    <div class="invalid-feedback">
                        <span v-if="!$v.folderName.maxLength" class="required-color error-message-v3">Folder name max. length is 255 characters.</span>
                        <span v-else-if="!$v.folderName.required" id="email-empty-fp" class="required-color error-message-v3">Folder name cannot be empty.</span>
                        <span v-else-if="invalidFolderName != ''" class="required-color error-message-v3">{{ invalidFolderName }}</span>
                    </div>
                <!-- </b-form-group> -->
                </div>
                <div class="d-flex justify-content-end align-items-center">
                    <span @click="close()" class="default-normal-size main-color-api mr-3 cursor-pointer">Cancel</span>
                    <button class="create-item-btn-v3 ml-3" :disabled="$v.$invalid">
                        <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                        Create
                    </button>
                </div>
            </b-form>
            <!-- <b-form class="p-2" @submit.prevent="submitEvent">
              <b-form-group id="input-group-1" class="py-2 form-group-mkdir" :valid-feedback="validFolderName" :invalid-feedback="invalidFolderName" :state="stateFolderName"   label-class="label-input"  label-for="input-1">
                <b-form-input 
                    id="input-1"
                    required
                    class="login-input-style"
                    :class="{'is-invalid': validationStatus($v.folderName)}"
                    v-model.trim="$v.folderName.$model" 
                    type="text"
                    placeholder="Folder name"
                    v-on:keydown="NameValidation($event)"></b-form-input>
                    <div class="invalid-feedback">
                        <span v-if="!$v.folderName.maxLength" class="required-color error-message-v3">Folder name max. length is 255 characters.</span>
                    </div>
              </b-form-group>
              <div class="mt-2 pt-2 d-flex justify-content-end align-items-center">
                   <a @click="close()" class="cursor-pointer default-text-style mr-4"><span class="api-main-color">Cancel</span></a>
                   <button class="btn-create-folder-v3" :disabled="loading || $v.$invalid || folderName == null || folderName == ''">
                     <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> 
                        Create
                    </button>
              </div>
            </b-form> -->
    </div>
</template>
<style scoped>

</style>